import React from "react";
import {TabbedForm, Edit, ImageField} from 'react-admin';
import {
    TrustedUsGeneralFormTab,
    TrustedUsMediaFormTab
} from "./TrustedUsForms";
import config from "../../config/config.json";


const TrustedUsEdit = (props) => {
    return (
        <Edit {...props}>
            <TabbedForm variant={config.form.variant} margin={config.form.margin}>
                <TrustedUsGeneralFormTab />
                <TrustedUsMediaFormTab>
                    <ImageField source="imageUrl" label="resources.trusted_us.fields.imageUrl" fullWidth />
                </TrustedUsMediaFormTab>
            </TabbedForm>
        </Edit>
    );
};

export default TrustedUsEdit;