import React from "react";
import {TabbedForm, Edit} from 'react-admin';
import config from "../../config/config.json";
import {
    BannersGeneralFormTab,
} from "./BannersForms";
import ImageUrlField from "../../components/fields/ImageUrlField";

const BannersEdit = props => (
    <Edit {...props}>
        <TabbedForm variant={config.form.variant} margin={config.form.margin}>
            <BannersGeneralFormTab />
            <ImageUrlField source="imageUrl" title="title" fullWidth/>
        </TabbedForm>
    </Edit>
);

export default BannersEdit;