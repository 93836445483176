import React from "react";
import {TabbedForm, Edit} from 'react-admin';
import config from "../../config/config.json";
import {
    CertificatesGeneralFormTab,
} from "./CertificatesForms";

const CertificatesEdit = props => (
    <Edit {...props}>
        <TabbedForm variant={config.form.variant} margin={config.form.margin}>
            <CertificatesGeneralFormTab />
        </TabbedForm>
    </Edit>
);

export default CertificatesEdit;