import React from 'react';
import ShowGuesser from "@api-platform/admin/lib/ShowGuesser";
import FieldGuesser from "@api-platform/admin/lib/FieldGuesser";
import { Datagrid, TextField, EditButton, ReferenceManyField, BooleanField, RichTextField, ImageField } from 'react-admin';
import ExpertAvatar from "../../components/addons/ExpertAvatar";

const Title = ({ record }) => {
    return (
        <span><ExpertAvatar record={record}/></span>
    )
};

const ExpertsShow = props => (
    <ShowGuesser {...props} title={<Title />}>
        <ImageField source="imageUrl" labelFrom="nameAndSurname" label="Expert photo" />
        <FieldGuesser source={"name"} addLabel={true} />
        <FieldGuesser source={"surname"} addLabel={true} />
        <FieldGuesser source={"academicDegree"} addLabel={true} />
        <RichTextField source="shortDescription" addLabel={true}/>
        <RichTextField source="description" addLabel={true}/>
        <FieldGuesser source={"active"} addLabel={true} />
        <BooleanField source={"promoted"} addLabel={true} />
        <FieldGuesser source={"createdAt"} addLabel={true} />
        <FieldGuesser source={"updatedAt"} addLabel={true} />
        <ReferenceManyField label="Trainings" reference="trainings" target="experts" sort={{ field: 'name', order: 'DESC' }}>
            <Datagrid>
                <TextField source="name" />
                <TextField source="subType" />
                <BooleanField source="active" />
                <EditButton />
            </Datagrid>
        </ReferenceManyField>
    </ShowGuesser>
);

export default ExpertsShow;