import { Redirect, Route } from "react-router-dom";
import {
    fetchHydra as baseFetchHydra,
    hydraDataProvider as baseHydraDataProvider,
    useIntrospection
} from "@api-platform/admin";
import { parseHydraDocumentation } from "@api-platform/api-doc-parser";
import JWTManager from "../utils/JWTManager";
import React from "react";

const entrypoint = process.env.REACT_APP_API_ENTRYPOINT;

// const getHeaders = () => JWTManager.getToken() !== null ? {
//     Authorization: `Bearer ${JWTManager.getToken()}`,
// } : {};
const getHeaders = () => ({Authorization: `Bearer ${JWTManager.getToken()}`});

const fetchHeaders = () => new Headers(getHeaders());
const fetchHydra = (url, options = {}) =>
    baseFetchHydra(url, {
        ...options,
        headers: fetchHeaders(),
    });

const apiDocumentationParser = entrypoint => parseHydraDocumentation(entrypoint, { headers: fetchHeaders() })
    .then(
        ({ api }) => ({ api }),
        (result) => {
            switch (result.status) {
                case 401:
                    JWTManager.ereaseToken();

                    return Promise.resolve({
                        api: result.api,
                        customRoutes: [
                            <Route path="/" render={() => {
                                return JWTManager.getToken() ? window.location.reload() : <Redirect to="/login" />;
                            }} />
                        ],
                    });

                default:
                    return Promise.reject(result);
            }
        },
    );

const dataProvider = baseHydraDataProvider(entrypoint, fetchHydra, apiDocumentationParser, true);

const myDataProvider = {
    ...dataProvider,
    create: (resource, params) => {
        // pola dodatkowe do platnych webinarow
        if (params.data.additionalFields) {
            params.data.webinarPaid = params.data.additionalFields
        }
        if (!params.data.attachments && !params.data.imageFile && !params.data.packageFile) {
            return dataProvider.create(resource, params);
        }

        let media = filterFiles(params.data);
        params.data.imageFile = null;
        params.data.attachments = null;
        params.data.packageFile = null;

        /**
         * For posts update only, convert uploaded image in base 64 and attach it to
         * the `
}picture` sent property, with `src` and `title` attributes.
         */
        // Freshly dropped pictures are File objects and must be converted to base64 strings
        return Promise.all(media.newFiles.map(convertFileToBase64))
            .then(base64Files => base64Files.map((picture64, index) => ({
                src: picture64,
                fileName: `${media.newFiles[index].title}`
            })))
            .then(transformedNewFiles => dataProvider.create(resource, {
                ...params,
                data: {
                    ...params.data,
                    base64Files: [
                        ...transformedNewFiles,
                        ...media.formerFiles,
                    ],
                },
            }));
    },
    update: (resource, params) => {
        // pola dodatkowe do platnych webinarow
        if (params.data.additionalFields) {
            params.data.webinarPaid = params.data.additionalFields
        }

        if (!params.data.attachments && !params.data.imageFile) {
            return dataProvider.update(resource, params);
        }

        let media = filterFiles(params.data);
        params.data.imageFile = null;
        params.data.attachments = null;



        return Promise.all(media.newFiles.map(convertFileToBase64))
            .then(base64Files => base64Files.map((picture64, index) => ({
                src: picture64,
                fileName: `${media.newFiles[index].title}`
            })))
            .then(transformedNewFiles => dataProvider.update(resource, {
                ...params,
                data: {
                    ...params.data,
                    base64Files: [
                        ...transformedNewFiles,
                        ...media.formerFiles,
                    ],
                },
            }));
    },
};

const filterFiles = data => {
    let media = {};
    if (data.attachments) {
        media.files = data.attachments;
    }
    if (data.packageFile) {
        media.files = data.packageFile;
    }
    if (data.imageFile) {
        media.files = data.imageFile;
    }

    media.newFiles = Array.isArray(media.files) === false ?
        [media.files] :
        media.files;

    return {
        newFiles: media.newFiles.filter(
            p => p.rawFile instanceof File
        ),
        formerFiles: media.newFiles.filter(
            p => !(p.rawFile instanceof File)
        )
    };
};

/**
 * Convert a `File` object returned by the upload input into a base 64 string.
 */
const convertFileToBase64 = file =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;

        reader.readAsDataURL(file.rawFile);
    });


export default myDataProvider;