import * as React from "react";
import { Link } from 'react-router-dom';
import { Button } from 'react-admin';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { linkToRecord } from 'ra-core';

export const CertificatePreview = ({ basePath, record, ...rest }) =>
    <Button
        component={Link}
        label="Generate"
        startIcon={<VisibilityIcon />}
        to={`${linkToRecord(basePath, record && record.id)}/preview`}
    />;