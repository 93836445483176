import TrustedUsIcon from '@material-ui/icons/FavoriteBorder';
import TrustedUsEdit from "./TrustedUsEdit";
import TrustedUsList from "./TrustedUsList";
import TrustedUsCreate from "./TrustedUsCreate";
import TrustedUsShow from "./TrustedUsShow";

export default {
    edit: TrustedUsEdit,
    create: TrustedUsCreate,
    list: TrustedUsList,
    show: TrustedUsShow,
    icon: TrustedUsIcon,
    options: {
        label: "Trusted Us"
    }
};