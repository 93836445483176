import React, {useState, useEffect} from "react";
import {ShowGuesser} from "@api-platform/admin";
import {
    TabbedShowLayout, Tab, TextField, DateField, ImageField, FunctionField, useNotify, useTranslate, Loading
} from 'react-admin';
import SimpleChipField from "../../components/fields/SimpleChipField";
import getQuizQuestions from "../../api/getQuizQuestions";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';

import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    title: {
        fontSize: theme.typography.pxToRem(18),
        margin: '20px 0'
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    answerCorrect: {
        '& svg': {
            color: 'green'
        }
    },
    answerWrong: {
        '& svg': {
            color: 'red'
        }
    },
    quizImage: {
        '& > img': {
            maxWidth: '250px'
        }
    }
}));

const DrawQuizQuestions = ({id}) => {
    const classes = useStyles();
    const notify = useNotify();
    const translate = useTranslate();
    const [quizQuestions, setQuizQuestions] = useState(null);

    useEffect(() => {
        getQuizQuestions({id: id})
            .then(data => setQuizQuestions(data))
            .catch(() => {
                notify(translate('Problem with fetch quiz questions'), 'error');
            });
    }, [setQuizQuestions]);

    if (quizQuestions === null) {
        return <Loading />;
    }

    return (
        <div className={classes.root}>
            <Typography component="h2" className={classes.title}>
                Lista pytań quizu
            </Typography>
            {quizQuestions.map((item, k) => (
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={`panel${item.id}a-content`}
                        id={`panel${item.id}a-header`}
                    >
                        <Typography className={classes.heading}>{item.question}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <List>
                            {item.answers.map(answer => (
                                <ListItem className={answer.correct ? classes.answerCorrect : classes.answerWrong}>
                                    <ListItemIcon>
                                        {answer.correct ? <CheckCircleOutlineIcon /> : <RadioButtonUncheckedIcon />}
                                    </ListItemIcon>
                                    <ListItemText primary={answer.answer} />
                                </ListItem>
                            ))}
                        </List>
                    </AccordionDetails>
                </Accordion>
            ))}
        </div>
    );
};

const QuizzesShow = (props) => {
    let quizIRIParts = props.id.split('/');
    let classes = useStyles();

    return (
        <ShowGuesser {...props}>
            <TabbedShowLayout>
                <Tab label="General">
                    <ImageField source="imageUrl" className={classes.quizImage}/>
                    <TextField source={"name"}/>
                    <FunctionField
                        label="resources.quizzes.fields.percentPointsToUnlockCertificate"
                        render={record => `${record.percentPointsToUnlockCertificate}%`}
                    />
                    <FunctionField
                        label="resources.quizzes.fields.trainings"
                        render={record => {
                            return record.training !== null ?
                                <SimpleChipField record={record.training.name}/> :
                                ' - ';
                        }}
                    />
                    <DateField source={"createdAt"} showTime/>
                    <DateField source={"updatedAt"} showTime/>
                </Tab>
                <Tab label="Questions">
                    {DrawQuizQuestions({id: quizIRIParts[quizIRIParts.length - 1]})}
                </Tab>
            </TabbedShowLayout>
        </ShowGuesser>
    )
};

export default QuizzesShow;