import JWTManager from "../utils/JWTManager";
import jwtDecode from 'jwt-decode';

const entrypoint = process.env.REACT_APP_API_ENTRYPOINT;

const authProvider = {
        login: ({username, password}) => {
            const request = new Request(`${entrypoint}/login_check`, {
                method: 'POST',
                body: JSON.stringify({username, password}),
                headers: new Headers({'Content-Type': 'application/json'})
            });
            return fetch(request)
                .then((response) => {
                    if (response.status < 200 || response.status >= 300) {
                        throw new Error(response.statusText);
                    }

                    return response.json();
                })
                .then(({token}) => {
                    JWTManager.setToken(token);
                    return Promise.resolve();
                });
        },
        logout: () => {
            JWTManager.ereaseToken();
            return Promise.resolve();
        },
        checkError: (error) => {
            if ([401, 403].includes(error?.status || error?.response?.status)) {
                console.log(error);
                JWTManager.ereaseToken();
                return Promise.reject();
            }
            return Promise.resolve();
        },
        checkAuth: () => {
            try {
                if (
                    !JWTManager.getToken() ||
                    new Date().getTime() / 1000 >
                    // @ts-ignore
                    jwtDecode(JWTManager.getToken())?.exp
                ) {
                    return Promise.reject();
                }
                return Promise.resolve();
            } catch (e) {
                // override possible jwtDecode error
                return Promise.reject();
            }
        },
        getPermissions: () => {
            return JWTManager.getToken() ? Promise.resolve() : Promise.reject();
        }
    }
;

export default authProvider;
