import React from "react";
import {TabbedForm, Create} from 'react-admin';
import {ExpertsGeneralFormTab, ExpertsMediaFormTab, ExpertsTrainingsFormTab,ExpertsSEOFormTab} from "./ExpertsForms";
import config from "../../config/config.json";

const ExpertsCreate = (props) => {
    return (
        <Create {...props} title="Add expert">
            <TabbedForm variant={config.form.variant} margin={config.form.margin}>
                <ExpertsGeneralFormTab />
                <ExpertsTrainingsFormTab />
                <ExpertsMediaFormTab />
                <ExpertsSEOFormTab />
            </TabbedForm>
        </Create>
    );
};

export default ExpertsCreate;