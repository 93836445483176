import JWTManager from "../utils/JWTManager";

const entrypoint = process.env.REACT_APP_API_ENTRYPOINT;

 const setCMSTrainingIndex = (trainingId='',ccmsId) => {
    return fetch(`${entrypoint}/cms/set_training_index/${trainingId}`, {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Authorization': `Bearer ${JWTManager.getToken()}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            ccmsId: ccmsId
        })
    }).then(response => {
        if (!response.ok) {
            throw new Error(response.statusText);
        }
        return response.json();
    });
}

export default setCMSTrainingIndex;