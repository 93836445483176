import React from 'react';
import {connect} from 'react-redux';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import {
    AutocompleteInput,
    Error,
    FormWithRedirect,
    ListButton,
    Loading,
    ReferenceInput,
    SaveButton,
    TextInput,
    Title,
    translate,
    useNotify,
    useQueryWithStore,
    required,
    number,
    minValue
} from 'react-admin';
import withStyles from '@material-ui/core/styles/withStyles';
import {compose} from '../../utils/utils';
import Typography from '@material-ui/core/Typography';
import certificatePreview from "../../api/certificatePreview";
import config from "../../config/config.json";

const validateUserId = [required(), number(), minValue(1)];

const entrypoint = process.env.REACT_APP_API_HOST;
const styles = {
    button: {
        marginTop: '1em'
    }
};

const CertificatesPreview = ({classes, translate, match}) => {
    const certificateId = decodeURIComponent(match.params.id);
    const {data, loading, error} = useQueryWithStore({
        type: 'getOne',
        resource: 'certificates',
        payload: {id: certificateId}
    });
    const notify = useNotify();

    function handleSubmit(values) {
        let productIRIParts = values.trainings.split('/');
        let certificateIRIParts = certificateId.split('/');
        const previewData = {
            user: values.user,
            productId: productIRIParts[productIRIParts.length - 1],
            certificateId: certificateIRIParts[certificateIRIParts.length - 1],
        };

        certificatePreview(previewData)
            .then(json => {
                const certificateUrl = `${entrypoint}${json.certificatePath}`;
                window.open(certificateUrl, '_blank');
            })
            .catch(error => {
                notify(error.message, 'error');
            });
    }

    if (loading) return <Loading/>;
    if (error) return <Error/>;
    if (!data) return null;

    return (
        <Card>
            <Title title={`${translate('resources.certificates.preview.Generate preview')} #${certificateId}`}/>
            <CardContent>
                <Typography component="h2">
                    {translate('Generate certificate')}
                </Typography>
                <Typography component="h4">{translate('resources.certificates.fields.name')}: {data.name}</Typography>
                <Typography component="h4">{translate('resources.certificates.fields.type')}: {data.type}</Typography>
                <Typography component="h4">{translate('resources.certificates.fields.path')}: {data.path}</Typography>
                <FormWithRedirect
                    save={handleSubmit}
                    render={({
                             handleSubmitWithRedirect,
                             pristine,
                             saving
                         }) => (
                            <>
                                <TextInput
                                    source="user"
                                    label={"resources.certificates.preview.user.id"}
                                    validate={validateUserId}
                                    fullWidth
                                    variant={config.form.variant}
                                    margin={config.form.margin}
                                />
                                <ReferenceInput
                                    source="trainings"
                                    reference="trainings"
                                    perPage={0}
                                    filter={{pagination: false}}
                                    label={"resources.certificates.preview.training"}
                                    fullWidth
                                    validate={required()}
                                    variant={config.form.variant}
                                    margin={config.form.margin}
                                >
                                    <AutocompleteInput optionText="name"/>
                                </ReferenceInput>
                                <SaveButton
                                    handleSubmitWithRedirect={
                                        handleSubmitWithRedirect
                                    }
                                    pristine={pristine}
                                    saving={saving}
                                    disabled={loading}
                                    label="Generate"
                                />
                            </>
                        )
                    }
                />

                <ListButton
                    variant="text"
                    to="/certificates"
                    label={translate("back to certificates list")}
                    className={classes.button}
                />
            </CardContent>
        </Card>
    );
};

const mapStateToProps = state => ({
    theme: state.theme
});

const enhance = compose(
    connect(mapStateToProps),
    translate,
    withStyles(styles)
);

export default enhance(CertificatesPreview);