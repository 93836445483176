import JWTManager from "../utils/JWTManager";

const entrypoint = process.env.REACT_APP_API_ENTRYPOINT;

 const getCategoryTrainings = (categoryId,returnType) => {
    if (typeof categoryId === undefined) {
        throw new Error("CategoryId is null")
    }
    return fetch(`${entrypoint}/categories/${categoryId}/nested_trainings?order[name]=asc&pagination=false`, {
        method: 'GET',
        mode: 'cors',
        headers: {
            'Authorization': `Bearer ${JWTManager.getToken()}`,
            'Content-Type': 'application/json',
            'Accept': typeof returnType !== 'string'?'*/*':returnType
        }
    }).then(response => {
        if (!response.ok) {
            throw new Error(response.statusText);
        }
        return response.json();
    });
}

export default getCategoryTrainings;