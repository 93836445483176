import React from "react";
import {TabbedForm, Create, ImageField} from 'react-admin';
import config from "../../config/config.json";
import {
    AdsGeneralFormTab, AdsJsCodeUrlFormTab, AdsMediaFormTab, AdsTrainingsFormTab
} from "./AdsForms";

const AdsCreate = props => (
    <Create {...props}>
        <TabbedForm variant={config.form.variant} margin={config.form.margin}>
            <AdsGeneralFormTab />
            <AdsMediaFormTab>
                <ImageField source="imageUrl" fullWidth />
            </AdsMediaFormTab>
            <AdsJsCodeUrlFormTab />
        </TabbedForm>
    </Create>
);

export default AdsCreate;