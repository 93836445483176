import React from 'react';
import ShowGuesser from "@api-platform/admin/lib/ShowGuesser";
import {
    TabbedShowLayout, Tab, TextField, BooleanField, RichTextField,
    NumberField, SelectField, DateField
} from 'react-admin';

const Title = ({ record }) => {
    return (
        <span>{`Subskrypcja "${record.name}"`}</span>
    )
};

const SubscriptionsShow = props => (
    <ShowGuesser {...props} title={<Title />}>
        <TabbedShowLayout>
            <Tab label="General">
                <TextField source={"name"} addLabel={true} />
                <RichTextField source="description" addLabel={true}/>
                <BooleanField source={"active"} addLabel={true} />
                <DateField source={"createdAt"} showTime addLabel={true}/>
                <DateField source={"updatedAt"} showTime addLabel={true}/>
            </Tab>
            <Tab label="resources.subscriptions.configuration">
                <TextField source="marketingCodePayu" addLabel={true}/>
                <TextField source="marketingCodeInvoice" addLabel={true}/>
                <NumberField source="accessLevel" addLabel={true}/>
                <SelectField source="subType" choices={[
                    { id: 'month', name: 'resources.subscriptions.fields.type.month' },
                    { id: 'quarter', name: 'resources.subscriptions.fields.type.quarter' },
                    { id: 'semiAnnual', name: 'resources.subscriptions.fields.type.semiAnnual' },
                    { id: 'annual', name: 'resources.subscriptions.fields.type.annual' },
                    { id: 'test', name: 'resources.subscriptions.fields.type.test' }
                ]} addLabel={true} />
                <NumberField source={"priceNet"} addLabel={true} />
                <NumberField source={"priceGross"} addLabel={true} />
                <NumberField source={"taxValue"} addLabel={true} />
            </Tab>
        </TabbedShowLayout>
    </ShowGuesser>
);

export default SubscriptionsShow;