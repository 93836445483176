import JWTManager from "../utils/JWTManager";

const entrypoint = process.env.REACT_APP_API_ENTRYPOINT;

const getQuizQuestions = ({id}) => {
    let url = `${entrypoint}/quizzes/${id}/questions`;
    return fetch(url, {
        method: 'GET',
        mode: 'cors',
        headers: {
            'Authorization': `Bearer ${JWTManager.getToken()}`,
            'Content-Type': 'application/json'
        }
    })
        .then(response => {
            if (!response.ok) {
                throw new Error(response.statusText);
            }
            return response.json();
        })
}

export default getQuizQuestions;