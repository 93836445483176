import React from "react";
import {
    FormTab,
    TextInput,
    ImageInput,
    ImageField,
    NumberInput,
    BooleanInput,
    AutocompleteArrayInput,
    ReferenceArrayInput,
    required,
    maxLength
} from 'react-admin';
import RelTypes from "../../components/fields/RelTypes";

const validateName = [required()];
const validateAlt = [required()];
const validateUrl = [maxLength(1024)];



export const TrustedUsGeneralFormTab = ({...rest}) =>
    <FormTab label="Content" {...rest}>
        <TextInput source={"name"} label="resources.trusted_us.fields.name" fullWidth validate={validateName}/>
        <TextInput source={"alt"} label="resources.trusted_us.fields.alt" fullWidth validate={validateAlt}/>
        <TextInput source={"url"} label="resources.trusted_us.fields.url" fullWidth validate={validateUrl}/>
        <NumberInput source={"sort"} label="resources.trusted_us.fields.sort" fullWidth validate={required()}/>
        <RelTypes source={"relType"} label="resources.trusted_us.fields.reltype" fullWidth />
        <BooleanInput source={"active"} label="resources.trusted_us.fields.active" />
    </FormTab>

export const TrustedUsMediaFormTab = ({children, ...rest}) => (
    <FormTab label="Media" {...rest}>
        <ImageInput
            fullWidth
            source="imageFile"
            label="resources.trusted_us.fields.image"
            accept=".jpg,.jpeg,.png,.webp,.gif"
            placeholder={<p>Dodaj banner</p>}
            multiple={false}
        >
            <ImageField source="imageFile" title="title" />
        </ImageInput>
        {children}
    </FormTab>
);