import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';

const entrypoint = process.env.REACT_APP_API_HOST;
const useStyles = makeStyles((theme) => ({
    expertAvatar: {
        width: theme.spacing(3),
        height: theme.spacing(3),
        float: 'left',
        margin: '4px 5px 0 0'
    },
}));

const ExpertAvatar = (props) => {
    let { record, withName = true } = props;
    let classes = useStyles();

    return (
        <React.Fragment>
            <Avatar className={classes.expertAvatar} src={`${entrypoint}/${record.imageUrl}`} />
            { withName ? `Ekspert ${record.nameAndSurname} (${record.id})` : null }
        </React.Fragment>
    )
};

export default ExpertAvatar;