import React from "react";
import {
    FormTab,
    TextInput,
    ImageInput,
    ImageField,
    AutocompleteArrayInput,
    ReferenceArrayInput,
    required,
    maxLength
} from 'react-admin';
import RelTypes from "../../components/fields/RelTypes";

const validateName = [required()];
const validateAlt = [required()];
const validateUrl = [maxLength(1024)];



export const PatronagesGeneralFormTab = ({...rest}) =>
    <FormTab label="Content" {...rest}>
        <TextInput source={"name"} fullWidth validate={validateName}/>
        <TextInput source={"alt"} fullWidth validate={validateAlt}/>
        <TextInput source={"url"} fullWidth validate={validateUrl}/>
        <RelTypes source={"relType"} label="resources.patronages.fields.reltype" fullWidth />
    </FormTab>

export const PatronagesTrainingsFormTab = ({...rest}) => (
    <FormTab label="Trainings" path="trainings" {...rest}>
        <ReferenceArrayInput
            label="Trainings"
            reference="trainings"
            source="trainings"
            filter={{ pagination: false }}
            fullWidth
            format={v => {
                if (!v) {
                    return null;
                }
                for (let i = 0; i < v.length; i++) {
                    if (typeof v[i] === 'object') {
                        v[i] = v[i]['@id'];
                    }
                }
                return v;
            }}
        >
            <AutocompleteArrayInput />
        </ReferenceArrayInput>
    </FormTab>
);

export const PatronagesMediaFormTab = ({children, ...rest}) => (
    <FormTab label="Media" {...rest}>
        <ImageInput
            fullWidth
            source="imageFile"
            label="resources.patronages.fields.image"
            accept=".jpg,.jpeg,.png,.webp,.gif"
            placeholder={<p>Dodaj banner</p>}
            multiple={false}
        >
            <ImageField source="imageFile" title="title" />
        </ImageInput>
        {children}
    </FormTab>
);