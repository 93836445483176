import React from "react";
import Button from '@material-ui/core//Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core//DialogContentText';
import DialogTitle from '@material-ui/core//DialogTitle';

export default function InfoDialog({title, message,dialogCloseSetState}) {
    const [open, setOpenInfoDialog] = React.useState(true);

    const handleClickOpen = () => {
        setOpenInfoDialog(true);
    };

    const handleClose = () => {
        dialogCloseSetState(false)
        setOpenInfoDialog(false);
    };

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="info-dialog-title"
                aria-describedby="info-dialog-description"
            >
                <DialogTitle id="info-dialog-title">
                    {title}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="info-dialog-description">
                        {message}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Zamknij</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}