import React from "react";
import RichTextInput from "ra-input-rich-text";
import {
    BooleanInput, TextInput, FormTab, ReferenceInput, SelectInput, AutocompleteInput,
    AutocompleteArrayInput, ReferenceArrayInput, required, ArrayInput, SimpleFormIterator, maxLength

} from 'react-admin';
import CategoryTrainingsSelect from "../../components/inputs/CategoryTrainingsSelect";
import QuizzesWipSelect from "../../components/inputs/QuizzesWipSelect";

const validateName = [required()];
const validateParent = [required()];
const validateMetaTileLength = [maxLength(70)];
const validateMetaDescription = [maxLength(160)];

export const CategoriesSEOFormTab = ({...rest}) => {
    return (
        <FormTab label="SEO" {...rest}>
            <TextInput source={"metaTitle"} fullWidth validate={validateMetaTileLength}/>
            <TextInput source={"metaDescription"} fullWidth validate={validateMetaDescription}/>
            <ArrayInput source={"keywords"}>
                <SimpleFormIterator>
                    <TextInput label={"resources.categories.fields.keyword"} fullWidth />
                </SimpleFormIterator>
            </ArrayInput>
        </FormTab>
    )
}

export const CategoriesGeneralFormTab = ({...rest}) => {
    return (
        <FormTab label="General" {...rest}>
            {/* w <Edit transform={} zmieniamy przed zapisem wartosc brand na uri */}
            <ReferenceInput
                label="resources.categories.fields.brand"
                source="brand"
                reference="portal_brands"
                fullWidth
                perPage={0}
                filter={{ pagination: false }}
                format={(value) => (value && typeof value === 'object' ? value['@id'] : value)}
                parse={(value) => (typeof value === 'object' ? value['@id'] : (value===''?null:value))}
            >
                <SelectInput optionText="name" resettable={true}/>
            </ReferenceInput>
            <TextInput source={"name"}  fullWidth validate={validateName} />
            <TextInput source={"description"} fullWidth multiline={true}/>
            <ReferenceInput
                source="parent"
                reference="categories"
                label="Category parent"
                sort={{ field: 'name', order: 'ASC' }}
                fullWidth
                validate={validateParent}
                format={v => {
                    if (!v) {
                        return null;
                    }
                    return typeof(v) === 'object' ? v['@id'] : v;
                }}
            >
                <SelectInput optionText="name" />
            </ReferenceInput>
            <BooleanInput source={"active"} />
        </FormTab>
    );
}

export const CategoriesTrainingsFormTab = ({...rest}) => {
    return (
        <FormTab label="Szkolenia" {...rest}>
            <CategoryTrainingsSelect
                source="promotedTraining"
                label={"resources.categories.fields.promotedTraining"}
                fullWidth
                allowEmpty={true}
                perPage={200}
                format={v => {
                    if (!v) {
                        return null;
                    }
                    return typeof(v) === 'object' ? v['@id'] : v;
                }}
                filter={{active: true}}
            />

            <ReferenceArrayInput
                label="Trainings"
                reference="trainings"
                source="trainings"
                filter={{ pagination: false }}
                fullWidth
                format={v => {
                    if (!v) {
                        return null;
                    }
                    for (let i = 0; i < v.length; i++) {
                        if (typeof v[i] === 'object') {
                            v[i] = v[i]['@id'];
                        }
                    }
                    return v;
                }}
            >
                <AutocompleteArrayInput optionValue="@id" />
            </ReferenceArrayInput>
        </FormTab>
    );
}