import React from "react";
import {
    Show, SimpleShowLayout, TextField, NumberField, ArrayField, Datagrid, DateField, SelectField,
} from 'react-admin';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    root: {
        width: '30%'
    },
    noActions: {
        marginTop: 0
    },
    userField: {
        marginTop: '8px',
        marginBottom: '4px',
        '& > h5': {
            color: 'rgba(0, 0, 0, 0.54)',
            transform: 'translate(0, 1.5px) scale(0.75)',
            transformOrigin: 'top left'
        },
        '& ul': {
            margin: '0 0 5px',
            '& li': {
                '& > h5': {
                    fontSize: '14px',
                    '& span': {
                        color: 'rgba(0, 0, 0, 0.54)'
                    }
                }
            }
        }
    }
});

const BuyerField = ({ record, ...props }) => {
    const classes = useStyles();
    if (!record.buyer) {
        return '';
    }

    return (
        <div className={classes.userField}>
            <Typography component={"h5"}>Buyer info</Typography>
            <ul>
                {
                    Object.keys(record.buyer).filter(e => record.buyer[e]!==null).map((e, i) => {
                        return <li key={`${record.id} ${e}`}><Typography component="h5"><span>{e}</span>: {record.buyer[e].toString()}</Typography></li>;
                    })
                }
            </ul>
        </div>
    )
}

const AsideOrders = (props) => {
    const classes = useStyles();
    return (
        <Show id={props.id} resource={props.resource} basePath={props.basePath} actions={null}  classes={{
            root: classes.root,
            noActions: classes.noActions
        }}>
            <SimpleShowLayout>
                <SelectField source="status" choices={[
                    { id: 'PROGRESS', name: 'resources.orders.fields.status.progress' },
                    { id: 'PENDING', name: 'resources.orders.fields.status.pending' },
                    { id: 'COMPLETE', name: 'resources.orders.fields.status.complete' },
                    { id: 'CANCEL', name: 'resources.orders.fields.status.cancel' },
                    { id: 'FAILURE', name: 'resources.orders.fields.status.failure' },
                    { id: 'ABANDON', name: 'resources.orders.fields.status.abandon' }
                ]} />
                <TextField source="orderId" fullWidth/>
                <NumberField source="paymentId" fullWidth/>
                <NumberField source="userId" fullWidth/>
                <ArrayField source={"cart"}>
                    <Datagrid>
                        <NumberField source={"productId"} fullWidth/>
                        <NumberField source={"quantity"} fullWidth/>
                    </Datagrid>
                </ArrayField>
                <BuyerField />
                <DateField source={"createdAt"} showTime/>
                <DateField source={"updatedAt"} showTime/>
                <DateField source={"paymentAt"} showTime/>
            </SimpleShowLayout>
        </Show>
    );
}

export default AsideOrders;