import React from 'react';
import ListGuesser from "@api-platform/admin/lib/ListGuesser";
import FieldGuesser from "@api-platform/admin/lib/FieldGuesser";
import { TextField, SingleFieldList, ChipField, ArrayField, BooleanField, ImageField } from 'react-admin';

const ExpertsList = props => {
    return (
        <ListGuesser {...props} title="Experts list" sort={{ field: 'name', order: 'ASC' }}>
            <ImageField source="imageUrl" labelFrom="nameAndSurname" label="Expert photo"/>
            <TextField source={"nameAndSurname"} label="Name and surname"/>
            <FieldGuesser source={"active"}/>
            <BooleanField source="promoted"/>
            <ArrayField source={"trainings"} fieldKey="@id" basePath={"/trainings"}>
                <SingleFieldList linkType="show">
                    <ChipField source="name"/>
                </SingleFieldList>
            </ArrayField>
            <FieldGuesser source={"createdAt"} label="Created at"/>
            <FieldGuesser source={"updatedAt"} label="Updated at"/>
        </ListGuesser>
    );
};

export default ExpertsList;