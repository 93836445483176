import React from "react";
import {
    FormTab, TextInput, BooleanInput, SelectInput, NumberInput, ArrayInput, SimpleFormIterator, required
} from 'react-admin';
import RichTextInput from "ra-input-rich-text";

const validateName = [required()];
const validatePriceNet = [required()];
const validateSubType = [required()];

export const OrdersGeneralFormTab = ({...rest}) =>
    <FormTab label="Content" {...rest}>
        <TextInput source={"name"} fullWidth validate={validateName}/>
        <RichTextInput source="description" />
        <ArrayInput source="benefits">
            <SimpleFormIterator>
                <TextInput label="resources.subscriptions.fields.benefits" fullWidth />
            </SimpleFormIterator>
        </ArrayInput>
        <BooleanInput source={"active"} fullWidth/>
        <NumberInput source="priceNet" fullWidth validate={validatePriceNet}/>
        <SelectInput source="subType" choices={[
            { id: 'month', name: 'resources.subscriptions.fields.type.month' },
            { id: 'quarter', name: 'resources.subscriptions.fields.type.quarter' },
            { id: 'semiAnnual', name: 'resources.subscriptions.fields.type.semiAnnual' },
            { id: 'annual', name: 'resources.subscriptions.fields.type.annual' }
        ]} fullWidth validate={validateSubType}/>
    </FormTab>
