import TrainingsIcon from '@material-ui/icons/LibraryBooks';
import TrainingsList from './TrainingsList';
import TrainingsCreate from './TrainingsCreate';
import TrainingsShow from './TrainingsShow';
import TrainingsEdit from "./TrainingsEdit";

export default {
    show: TrainingsShow,
    create: TrainingsCreate,
    edit: TrainingsEdit,
    list: TrainingsList,
    icon: TrainingsIcon,
    options: {
        label: "Trainings"
    }
};