import React, {useEffect, useState} from "react";
import {LinearProgress, useNotify, useTranslate,AutocompleteInput} from 'react-admin';
import getCategoryTrainings from "../../api/getCategoryTrainings";

const CategoryTrainingsSelect = (props) => {
    const notify = useNotify();
    const translate = useTranslate();
    const [categoryTrainings, setCategoryTrainings] = useState(null);
    const categoryId = props.record.originId ?? 1;

    useEffect(() => {
        getCategoryTrainings(categoryId,'application/ld+json')
            .then(categoryTrainingsList => {
                // to w przyszłości jakoś wypadałoby poprawić żeby nie czytac z hydra:member tylko jakoś parsować /RK
                 setCategoryTrainings(categoryTrainingsList['hydra:member'].map(item => {
                    return {
                        id: item['@id'],
                        name: item.name
                    }}));
            })
            .catch((e) => {
                console.error(e);
                notify(translate('Problem with fetch category trainings'), 'error');
            });
    }, [setCategoryTrainings]);

    if (categoryTrainings === null) {
        return <LinearProgress/>;
    }

    return (
        <AutocompleteInput
            {...props}
            choices={categoryTrainings}
        />
    );
}

export default CategoryTrainingsSelect;