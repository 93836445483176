import React from "react";
import 'rc-tree/assets/index.css';
import '../../assets/rc-tree.custom.css';
import Tree from "rc-tree";
import {Loading} from 'react-admin';
import InfoDialog from "../../components/outputs/InfoDialog";
import getCMSTreeNode from "../../api/getCMSTreeNode";
import getCMSTrainingIndex from "../../api/getCMSTrainingIndex";
import setCMSTrainingIndex from "../../api/setCMSTrainingIndex";
import Typography from '@material-ui/core/Typography';
import {Input,Button} from '@material-ui/core';

class CMSTree extends React.Component {
    state = {
        treeData: [],
        ccmsId:null,
        pairIndice:false,
        dialogShow: false,
        expandedTreeNode: null
    };

    constructor(props) {
        super(props)
        this.dialog = {
            title: 'Info',
            message: ''
        }
    }

    componentDidMount() {
        async function getEntities(that) {
            getCMSTreeNode().then((jsonTree) => {
                const entityData = jsonTree.map((item) => ({
                    title: item.name,
                    key: item.id,
                    isLeaf: !item.hasChilds
                }));

                that.setState({treeData: entityData})
            });
        }
        getCMSTrainingIndex(this.props.record.originId).then((jsonTree) => {
            this.setState({ccmsId:jsonTree.ccmsId});
        });

        getEntities(this);
    }

    onSelect = (ccmsId) => {
        this.setState({ccmsId:ccmsId[0]});
        console.log(ccmsId[0], this.props.record.originId)
    };

    onCheck = (checkedKeys) => {
        this.setState({
            checkedKeys
        });
    };

    onLoadData = treeNode => {
        return new Promise((resolve, reject) => {
            if (treeNode.children) {
                resolve();
                return;
            }

            let { treeData } = this.state;
            treeData = treeData.slice();
            const matchingNode = this.findExpandedNode(treeNode.props.eventKey, treeData);

            getCMSTreeNode(treeNode.props.eventKey).then((jsonTree) => {
                const entityData = jsonTree.map((item) => ({
                    title: item.name,
                    key: item.id,
                    isLeaf: !item.hasChilds,
                }))

                if (entityData.length > 0) {
                    matchingNode.children = entityData;

                    this.setState({treeData});
                }
            resolve();
            }).catch(err =>{reject(err)});
        });
    };


    showDialogMessage = (title, message) => {
        this.dialog.title = title;
        this.dialog.message = message;
        this.setState({dialogShow:true});
    }

    dialogClose = ({state}) => {
        this.setState({dialogShow:state});
    }

    onNodeExpand = expandedTreeNode => {
            this.setState({expandedTreeNode});
    }

    handleSubmitCMSId = (e) => {
        e.preventDefault();
        this.setState({pairIndice:true});
        return new Promise((resolve) => {
            setCMSTrainingIndex(this.props.record.originId, this.state.ccmsId).then((jsonTree) => {
                this.setState({pairIndice:false});
                this.showDialogMessage("Synchronizacja materiałów do szkolenia", "Pobranych dokumentów: "+jsonTree.fetched.toString());
                resolve();
            });
        });
    }

    findExpandedNode = (nodeId, treeData) => {
        let searchInTree = (treeData) => {
            let findedNode = null;
            treeData.forEach(function (nodeLeaf) {
                if (findedNode !== null)
                    return;
                if (nodeLeaf.key === nodeId) {
                   findedNode = nodeLeaf;
                   return;
                } else if (nodeLeaf.children) {
                    let node = searchInTree(nodeLeaf.children)
                    if (node !== null)
                        findedNode = node;
                    return;
                }
            })
            return findedNode;
        }
        return searchInTree(treeData);
    }

    render() {
        const isDialogShow = this.state.dialogShow;
        let InfoDialogBox;
        if (isDialogShow) {
            InfoDialogBox = <InfoDialog title={this.dialog.title} message={this.dialog.message} dialogCloseSetState={this.dialogClose} />;
        }

        return (
            <div>
                {InfoDialogBox}
                <Typography variant="h5" gutterBottom component="div">CMS Indeks z materiałami</Typography>
                <Input variant="outlined" name={"ccmsId"} value={this.state.ccmsId} />
                {this.state.pairIndice? (
                    <Loading loadingPrimary="Aktualizacja..." loadingSecondary="Trwa pobieranie materiałów do szkolenia..." />
                ) : (
                    <Button variant="contained" onClick={this.handleSubmitCMSId}>Powiąż index ze szkoleniem</Button>
                )}
                <Tree
                    onSelect={this.onSelect}
                    checkable={false}
                    multiple={false}
                    loadData={this.onLoadData}
                    treeData={this.state.treeData}
                    autoExpandParent={false}
                    showLine={true}
                    onExpand={this.onNodeExpand}
                />
            </div>
        );
    }
}


export default CMSTree;