export const compose = (...args) => x => args.reduceRight((acc, currFn)=>{
    return currFn(acc)
}, x);

export const removeReturnUrl = (type) => {
    sessionStorage.removeItem(`ReturnUrl-${type}`);
};

export const saveReturnUrl = (type) => {
    sessionStorage.setItem(`ReturnUrl-${type}`, window.location);
};

export const getReturnUrl = (type) => {
    return sessionStorage.getItem(`ReturnUrl-${type}`);
}