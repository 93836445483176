import React from "react";
import {TabbedForm, Edit, ImageField} from 'react-admin';
import {
    AdsGeneralFormTab, AdsJsCodeUrlFormTab, AdsMediaFormTab, AdsTrainingsFormTab,
} from "./AdsForms";
import config from "../../config/config.json";

const AdsEdit = props => (
    <Edit {...props}>
        <TabbedForm variant={config.form.variant} margin={config.form.margin}>
            <AdsGeneralFormTab />
            <AdsMediaFormTab>
                <ImageField source="imageUrl" fullWidth />
            </AdsMediaFormTab>
            <AdsJsCodeUrlFormTab />
            <AdsTrainingsFormTab />
        </TabbedForm>
    </Edit>
);

export default AdsEdit;