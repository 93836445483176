import React from 'react';
import ListGuesser from "@api-platform/admin/lib/ListGuesser";
import FieldGuesser from "@api-platform/admin/lib/FieldGuesser";
import { TextField, SingleFieldList, ChipField, ArrayField, BooleanField, ImageField } from 'react-admin';

const AdsList = props => {
    return (
        <ListGuesser {...props} title="Ads list" sort={{ field: 'name', order: 'ASC' }}>
            <ImageField source="imageUrl" label="resources.ads.fields.image" />
            <TextField source={"name"} label="Name"/>
            <ArrayField source={"trainings"} fieldKey="@id" basePath={"/trainings"}>
                <SingleFieldList linkType="show">
                    <ChipField source="name"/>
                </SingleFieldList>
            </ArrayField>
            <FieldGuesser source={"createdAt"} label="Created at"/>
            <FieldGuesser source={"updatedAt"} label="Updated at"/>
        </ListGuesser>
    );
};

export default AdsList;