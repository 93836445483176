import * as React from "react";
import {
    BooleanField, TextField, TextInput, ArrayField, SingleFieldList, ChipField, Filter, List as AdminList, Datagrid,
    EditButton, ShowButton, DateField, ImageField
} from 'react-admin';
import {Card as MuiCard, CardContent, withStyles} from '@material-ui/core';
import {IsActiveFilter, IsArchiveFilter, DateCreatedGreaterThanFilter} from '../../components/filters';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import TrainingsTypesFilter from "../../components/filters/TrainingsTypesFilter";
import RaPagination from "../../components/pagination/RaPagination";

const Card = withStyles(theme => ({
    root: {
        [theme.breakpoints.up('sm')]: {
            order: -1, // display on the left rather than on the right of the list
            width: '15em',
            marginRight: '1em',
        },
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
}))(MuiCard);

const FilterSidebar = () => (
    <Card>
        <CardContent>
            <IsActiveFilter/>
            <IsArchiveFilter/>
            <DateCreatedGreaterThanFilter/>
            <TrainingsTypesFilter/>
        </CardContent>
    </Card>
);

const TrainingsFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Product name" source="name" alwaysOn/>
    </Filter>
);

const TrainingsExpand = ({id, record, resource}) => (
    <React.Fragment>
        <List component="nav" aria-label="main mailbox folders">
            <ListItem>
                <ListItemIcon>
                    <FiberManualRecordIcon/>
                </ListItemIcon>
                <ListItemText primary="Typ" secondary={record.subType}/>
            </ListItem>
            <ListItem>
                <ListItemIcon>
                    <FiberManualRecordIcon/>
                </ListItemIcon>
                <ListItemText primary="updatedAt" secondary={(
                    <React.Fragment>
                        {new Intl.DateTimeFormat('pl-PL', {
                            timeZone: 'UTC',
                            year: 'numeric',
                            month: 'long',
                            day: 'numeric',
                            hour: 'numeric',
                            minute: 'numeric',
                            second: 'numeric'
                        }).format(new Date(record.updatedAt))}
                    </React.Fragment>
                )}/>
            </ListItem>

        </List>
    </React.Fragment>
);

const TrainingsList = props => {
    return (
        <AdminList
            {...props}
            title="Trainings list"
            aside={<FilterSidebar/>}
            filters={<TrainingsFilter/>}
            pagination={<RaPagination type="Trainings"/>}
            sort={{field: 'createdAt', order: 'DESC'}}
        >
            <Datagrid expand={<TrainingsExpand/>}>
                <ImageField source="imageUrl" labelFrom="name" label="Product image"/>
                <TextField source={"subType"}/>
                <TextField source={"name"}/>
                <BooleanField source={"active"}/>
                <ArrayField source={"categories"} fieldKey="@id" basePath={"/categories"}>
                    <SingleFieldList linkType="show">
                        <ChipField source="name"/>
                    </SingleFieldList>
                </ArrayField>
                <DateField source={"createdAt"} showTime/>
                <DateField source={"legalStatusDate"} />
                <ShowButton/>
                <EditButton/>
            </Datagrid>
        </AdminList>
    )
};

export default TrainingsList;