import React from 'react';
import ShowGuesser from "@api-platform/admin/lib/ShowGuesser";
import FieldGuesser from "@api-platform/admin/lib/FieldGuesser";
import { SingleFieldList, ChipField, Datagrid, TextField, EditButton, ReferenceManyField, ArrayField, RichTextField, ImageField } from 'react-admin';

const PatronagesShow = props => (
    <ShowGuesser {...props} >
        <ImageField source="imageUrl" label="resources.patronages.fields.image" />
        <FieldGuesser source={"name"} addLabel={true} />
        <FieldGuesser source={"alt"} addLabel={true} />
        <FieldGuesser source={"url"} addLabel={true} />
        <FieldGuesser source={"createdAt"} addLabel={true} />
        <FieldGuesser source={"updatedAt"} addLabel={true} />
        <ArrayField label="resources.patronages.fields.trainings" source={"trainings"} fieldKey="@id" basePath={"/trainings"}>
            <SingleFieldList linkType="show">
                <ChipField source="name"/>
            </SingleFieldList>
        </ArrayField>


    </ShowGuesser>
);

export default PatronagesShow;