import React from "react";
import {
    Create, TabbedForm
} from 'react-admin';
import config from "../../config/config.json";
import CategoriesFormToolbar from "../../components/toolbars/CategoriesFormToolbar";
import {CategoriesGeneralFormTab, CategoriesTrainingsFormTab, CategoriesSEOFormTab} from "./CategoriesForms";

const CategoriesCreate = props => (
    <Create {...props} transform={data => {
        console.log("transform:", data);
        if (data.brand && typeof data.brand !== 'string' && data.brand !== undefined) {
            data.brand = data.brand['@id'];
        }
        return data;
    }}>
        <TabbedForm toolbar={<CategoriesFormToolbar />} variant={config.form.variant} margin={config.form.margin}>
            <CategoriesGeneralFormTab />
            <CategoriesSEOFormTab />
        </TabbedForm>
    </Create>
);

export default CategoriesCreate;