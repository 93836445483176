import React from "react";
import {
    FormTab, NumberInput, required, minValue, maxValue, ReferenceInput, SelectInput
} from 'react-admin';
import QuizzesWipSelect from "../../components/inputs/QuizzesWipSelect";

const validateQuestionnaireWipId = [required()];
const validatePercentPointsToUnlockCertificate = [required(), minValue(1), maxValue(100)];

export const QuizzesGeneralFormTab = ({...rest}) => {
    return (
        <FormTab label="General" {...rest}>
            <NumberInput source="percentPointsToUnlockCertificate" fullWidth validate={validatePercentPointsToUnlockCertificate}/>
            <QuizzesWipSelect source="questionnaireWipId" filterUsed={true} fullWidth validate={validateQuestionnaireWipId}/>
            <ReferenceInput source="training" reference="trainings" fullWidth
                filter={{
                    "exists[quiz]": false,
                    pagination: false
                }}
                perPage={0}
                format={v => {
                    if (!v) {
                        return null;
                    }
                    return typeof (v) === 'object' ? v['@id'] : v;
                }}
            >
                <SelectInput optionText="name" />
            </ReferenceInput>
        </FormTab>
    );
};