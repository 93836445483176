import React, {useEffect, useState} from "react";
import {SelectInput, LinearProgress, useNotify, useTranslate} from 'react-admin';
import PropTypes from 'prop-types';
import QuizzesWip from "../../api/getQuizzesWip";

const QuizzesWipSelect = (props) => {
    const notify = useNotify();
    const translate = useTranslate();
    const [quizzesWip, setQuizzesWip] = useState(null);

    useEffect(() => {
        QuizzesWip()
            .then(data => {
                let quizzesWipList = props.filterUsed ? data.filter(item => (props.record[props.source]===item.id || !item.used)) : data;
                setQuizzesWip(quizzesWipList.map(item => ({
                    id: item.id,
                    name: item.title
                })));
            })
            .catch(() => {
                notify(translate('Problem with fetch quizzes WiP'), 'error');
            });
    }, [setQuizzesWip]);

    if (quizzesWip === null) {
        return <LinearProgress/>;
    }

    return (
        <SelectInput
            {...props}
            choices={quizzesWip}
        />
    );
}
QuizzesWipSelect.propTypes = {
    filterUsed: PropTypes.bool
}
QuizzesWipSelect.defaultProps = {
    filterUsed: false
}
export default QuizzesWipSelect;