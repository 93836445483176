import React from 'react';
import { connect } from 'react-redux';
import { translate, MenuItemLink, getResources, Responsive, DashboardMenuItem } from 'react-admin';
import { withRouter } from 'react-router-dom';
import DefaultIcon from '@material-ui/icons/ViewList';
import SettingsIcon from '@material-ui/icons/Settings';
import {
    Divider
} from '@material-ui/core';
import {compose} from "../../utils/utils";

const DefaultMenu = ({
        resources,
        onMenuClick,
        logout,
        dense,
        open,
        hasDashboard,
        translate
    }) => (
    <div>
        {hasDashboard && (
            <DashboardMenuItem
                onClick={onMenuClick}
                dense={dense}
                sidebarIsOpen={open}
            />
        )}
        <Divider />
        {resources
            .filter(r => r.hasList)
            .map(resource => {
                return (
                    <React.Fragment key={`menu-${resource.name}`}>
                        {(resource.options && resource.options.dividerUp) ? <Divider /> : null}
                        <MenuItemLink
                            key={resource.name}
                            to={`/${resource.name}`}
                            primaryText={translate((resource.options && resource.options.label) || resource.name)}
                            leftIcon={
                                resource.icon ? <resource.icon/> : <DefaultIcon/>
                            }
                            onClick={onMenuClick}
                            dense={dense}
                            sidebarIsOpen={open}
                        />
                        {(resource.options && resource.options.dividerBottom) ? <Divider /> : null}
                    </React.Fragment>
                )
            })}
        <Divider />
        <Responsive
            small={logout}
            medium={null}
        />
    </div>
);

const mapStateToProps = state => ({
    resources: getResources(state),
});

const enhance = compose(
    withRouter,
    connect(
        mapStateToProps,
        {}
    ),
    translate
);

export default enhance(DefaultMenu);