import React, {useEffect, useState} from "react";
import PropTypes from 'prop-types';
import Vimeo from '@vimeo/player';
import {useForm} from 'react-final-form';
import {Loading} from 'react-admin';

const VimeoVideoLoader = (props) => {
    const form = useForm();
    const videoRef = React.createRef();
    let player;
    let options = {
        controls: true,
        width: 320,
        maxwidth: 320,
        height: 180,
        maxheight: 180,
        responsive: true,
        title: false,
        autopip: false,
        pip: false,
        screenfull: false,
    }

    if (props.width !== undefined) {
        options.width = options.maxwidth = props.width;
    }
    if (props.height !== undefined) {
        options.height = options.maxheight = props.height;
    }

    useEffect(() => {
        if (props.videoUrl)
            options.url = props.videoUrl
        else
            options.id = parseInt(props.videoId);
        try {
            player = new Vimeo(videoRef.current, options);
        } catch (error) {
            console.log(error)
        }
        console.log(player);
        getPlayerInfo();

    }, [props.videoId, props.videoUrl]);

    async function getPlayerInfo() {
        try {
            await player.ready();
            // TODO z jakiegos powodu gdy wpisujac id filmu trafimy na cos zablokowanego przestaje sie wykonywac getVideoId i calosc przestaje dzialac :/
            const videoId = await player.getVideoId();
            if (props.videoUrl && props.videoId === null)
            {
                form.change(props.sourceVideoId, videoId);
            } else {
                player.loadVideo(props.videoId||props.videoUrl);
            }
            const title = await player.getVideoTitle();
            form.change(props.sourceTitle, title);
            const duration = await player.getDuration();
            form.change(props.sourceDuration, duration);
            if (player.element.parentElement.style.position !== 'relative') {
                setIframeParams(player.element);
            }
        } catch (error) {
            player.destroy();
            console.log("BLAD",error);
        }
    }

    const setIframeParams = (iframeObject) => {
        if (iframeObject === null)
            return;
        videoRef.current = iframeObject.parentElement;

        iframeObject.width = '';
        iframeObject.height = '';
        Object.assign(iframeObject.style, {
            width: '100%',
            height: '100%',
            position: 'absolute',
            top: '0',
            left: '0',
        });

        let newContaniner = document.createElement('div');
        Object.assign(newContaniner.style, {
            padding: '56.25% 0px 0px',
            position: 'relative'
        });
        newContaniner.append(iframeObject);
        videoRef.current.append(newContaniner);
    }

    return (
        <div style={{width: options.width + 'px', height: options.height + 'px', display: 'block'}} ref={videoRef}/>
    );
}

export default VimeoVideoLoader;