import {useListPaginationContext} from "ra-core";
import {Pagination} from 'react-admin';
import * as React from "react";
import {useEffect} from "react";
import {removeReturnUrl, saveReturnUrl} from "../../utils/utils";

const RaPagination = props => {
  const {type} = props;
  const {
    page,
    setPage,
    perPage
  } = useListPaginationContext(props);

  useEffect(() => {
    const queryString = window.location.hash;
    const urlParams = new URLSearchParams(queryString);
    const paramPage = urlParams.get('page');

    if (paramPage===null) {
      setPage(1);
      removeReturnUrl(type);
    }
    else {
      saveReturnUrl(type);
    }
  }, [page, perPage]);

  return <Pagination
    {...props}
    rowsPerPageOptions={[10, 25, 50, 100]}
  />;
}

export default RaPagination;