import BannersIcon from '@material-ui/icons/BrandingWatermarkTwoTone';
import BannersList from './BannersList';
import BannersCreate from './BannersCreate';
import BannersEdit from './BannersEdit';
import BannersShow from './BannersShow';

export default {
    create: BannersCreate,
    list: BannersList,
    icon: BannersIcon,
    edit: BannersEdit,
    show: BannersShow,
    options: {
        dividerUp: true,
        label: "Banners"
    }
};