import React, {useEffect, useState} from "react";
import {SelectInput, useNotify, LinearProgress} from 'react-admin';
import JWTManager from "../../utils/JWTManager";

const entrypoint = process.env.REACT_APP_API_ENTRYPOINT;

const TrainingsContentsTypes = (props) => {
    const notify = useNotify();
    let trainingType = props.trainingType??'';

    const [trainingsContentsTypes, setTrainingsContentsTypes] = useState(null);

    useEffect(() => {
        fetch(`${entrypoint}/trainings/contents/types/${trainingType}`, {
            method: 'GET',
            mode: 'cors',
            headers: {
                'Authorization': `Bearer ${JWTManager.getToken()}`
            }
        })
            .then(result => {
                if (!result.ok) {
                    throw new Error(result.statusText);
                }
                return result.json();
            })
            .then(data => {
                setTrainingsContentsTypes(data.map(item => {
                    return {
                        id: item.type,
                        name: item.name
                    }
                }));
            })
            .catch(e => {
                setTrainingsContentsTypes([]);
                notify(`Error: problem with fetch trainings content types: ${e}`, 'warning');
            })
    }, [notify, setTrainingsContentsTypes]);

    if (trainingsContentsTypes === null) {
        return <LinearProgress />;
    }

    return (
        <SelectInput
            {...props}
            choices={trainingsContentsTypes}
        />
    );
};

export default TrainingsContentsTypes;