import React from "react";
import {
    FormTab,
    TextInput,
    SelectInput,
    required
} from 'react-admin';

const validateName = [required()];

export const CertificatesGeneralFormTab = ({...rest}) => {
    return (
        <FormTab label="General" {...rest}>
            <TextInput source="name" fullWidth validate={validateName}/>
            <SelectInput source="type" choices={[
                {id: 'default', name: 'default'}
            ]}/>
            <SelectInput source="path" choices={[
                {id: 'certificate/default.html.twig', name: 'default'},
                {id: 'certificate/quiz.html.twig', name: 'quiz'}
            ]}/>
        </FormTab>
    );
};