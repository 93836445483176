import PatronagesIcon from  '@material-ui/icons/LocalAtm';
import PatronagesEdit from "./PatronagesEdit";
import PatronagesList from "./PatronagesList";
import PatronagesCreate from "./PatronagesCreate";
import PatronagesShow from "./PatronagesShow";

export default {
    edit: PatronagesEdit,
    create: PatronagesCreate,
    list: PatronagesList,
    show: PatronagesShow,
    icon: PatronagesIcon,
    options: {
        label: "Patronages"
    }
};