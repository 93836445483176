import React, {Fragment, useState} from "react";
import {
    FormTab,
    DateInput,
    ArrayInput,
    SimpleFormIterator,
    FormDataConsumer,
    TextInput,
    BooleanInput,
    NumberInput,
    ImageInput,
    ImageField,
    AutocompleteArrayInput,
    ReferenceArrayInput,
    ReferenceInput,
    SelectInput,
    useTranslate,
    number,
    required,
    maxLength,
    minLength,
    DateTimeInput,
    ArrayField,
    Datagrid,
    ReferenceField,
    TextField,
    AutocompleteInput,
    addField,
    regex,
    Button
} from 'react-admin';
import {Grid, FormControlLabel, Radio, RadioGroup, styled} from '@material-ui/core';
import VimeoIframe from "../../components/addons/VimeoIframe";
import RichTextInput from "ra-input-rich-text";
import TrainingsTypes from "../../components/fields/TrainingsTypes";
import {makeStyles} from '@material-ui/core/styles';
import TrainingsContentsTypes from "../../components/fields/TrainingsContentsTypes";
import VimeoVideoLengthInput from "../../components/inputs/VimeoVideoLengthInput";
import CMSTree from "../../components/tree/CMSTree"
import VimeoVideoLoader from "../../components/inputs/VimeoVideoLoader";
import {useForm} from 'react-final-form';

const validateRequired = [required()];
const validateName = [required()];
const validateType = [required()];
const validateContentType = [required()];
const validateContentText = [required()];
const validateVimeoId = [required()];
const validateTitleVideo = [required()];
const validateSortVideo = [required(), number()];
const validateMetaTileLength = [maxLength(70)];
const validateMetaDescription = [maxLength(160)];
const validateConfig = [required(), maxLength(4), minLength(4)];
const validateVimeoUrl = [regex(/^https:\/\/(?:[a-z]+\.)?vimeo.com\/(?:video|event)\/[0-9]+(?:\/[a-zA-Z0-9]+)?$/, "Podaj pełny adres do filmu lub eventu w serwisie Vimeo")]

const useStyles = makeStyles({
    sortInput: {},
    line: {
        margin: '0 0 20px',
        padding: '10px',
        '&:nth-child(2n+1):not(:last-child)': {
            border: 'solid 1px rgba(0, 0, 0, 0.12)'
        }
    },
    iframeVideoContainer: {
        margin: '8px 20px 10px 0',
        float: 'left',
        '& iframe': {}
    },
});

export const TrainingsTranscriptionFormTab = ({...rest}) => {
    const translate = useTranslate();
    return (
        <FormTab label="Transcription" {...rest}>
            <TextInput label={translate("resources.trainings.fields.transcription")} source={"transcription"}
                       helperText={translate("Audio transcription text")} fullWidth multiline={true}/>
        </FormTab>
    )
}

export const TrainingsSEOFormTab = ({...rest}) => {
    return (
        <FormTab label="SEO" {...rest}>
            <TextInput source={"metaTitle"} fullWidth validate={validateMetaTileLength}/>
            <TextInput source={"metaDescription"} fullWidth validate={validateMetaDescription}/>
            <ArrayInput source={"keywords"}>
                <SimpleFormIterator>
                    <TextInput label={"resources.trainings.fields.keyword"} fullWidth/>
                </SimpleFormIterator>
            </ArrayInput>
        </FormTab>
    )
}

export const TrainingsGeneralFormTab = ({isEdit, trainingType, setTrainingType, ...rest}) => {
    if (rest.record !== undefined && rest.record.subType !== undefined) setTrainingType(rest.record.subType);

    return (
        <FormTab label="General" {...rest}>
            <Grid container spacing={1} style={{width: "100%",}}>
                <Grid item xs={12}>
                    <TextInput label="resources.trainings.fields.name" source="name" fullWidth validate={validateName}/>
                </Grid>
            </Grid>
            <Grid container spacing={1} style={{width: "100%",}}>
                <Grid item xs={12} md={6}>
                    <TrainingsTypes label="resources.trainings.fields.type" fullWidth validate={validateType}
                                    source={"subType"} onChange={(e) => {
                        setTrainingType(e.target.value)
                    }} disabled={isEdit}/>
                </Grid>

                {trainingType === 'webinarPaid' &&
                    <Grid item xs={12} md={6}><BooleanInput source={"additionalFields.hasChat"}
                                                            label={"resources.trainings.fields.webinar.paid.hasChat"}/></Grid>
                }

            </Grid>
            <Grid container spacing={1} style={{width: "100%",}}>
                <Grid item xs={12} md={6}>
                    <ReferenceArrayInput
                        label="Categories"
                        reference="categories"
                        source="categories"
                        fullWidth
                        perPage={0}
                        filter={{pagination: false}}
                        format={v => {
                            if (!v) {
                                return;
                            }
                            for (let i = 0; i < v.length; i++) {
                                if (typeof v[i] === 'object') {
                                    v[i] = v[i]['@id'];
                                }
                            }
                            return v;
                        }}
                    >
                        <AutocompleteArrayInput optionText={(choice) => {
                            if (typeof choice?.name !== 'undefined') {
                                return `${choice.name} (poziom: ${choice.lvl})`;
                            }
                        }}/>
                    </ReferenceArrayInput>
                </Grid>

                <Grid item xs={12} md={6}>
                    <ReferenceArrayInput
                        label="Experts"
                        reference="experts"
                        source="experts"
                        fullWidth
                        perPage={500}
                        format={v => {
                            if (!v) {
                                return;
                            }
                            for (let i = 0; i < v.length; i++) {
                                if (typeof v[i] === 'object') {
                                    v[i] = v[i]['@id'];
                                }
                            }
                            return v;
                        }}
                    >
                        <AutocompleteArrayInput optionText={(choice) => {
                            if (typeof choice?.nameAndSurname !== 'undefined') {
                                return `${choice.nameAndSurname}`;
                            }
                        }}/>
                    </ReferenceArrayInput>
                </Grid>
            </Grid>
            <Grid container spacing={1} style={{width: "100%",}}>
                {trainingType === 'webinarPaid' &&
                    <Grid item xs={4} md={2}><TextInput fullWidth source={"additionalFields.configuration"}
                                                        label={"resources.trainings.fields.webinar.paid.configuration"}
                                                        validate={validateConfig}
                    /></Grid>
                }

                {trainingType === 'webinarPaid' &&
                    <Grid item xs={4} md={2}><NumberInput fullWidth source={"additionalFields.priceNet"} step={0.01}
                                                          label={"resources.trainings.fields.webinar.paid.priceNet"}
                                                          validate={validateRequired}
                    /></Grid>
                }
                {trainingType === 'webinarPaid' &&
                    <Grid item xs={4} md={2}><NumberInput fullWidth source={"additionalFields.priceGross"} step={0.01}
                                                          label={"resources.trainings.fields.webinar.paid.priceGross"}
                                                          validate={validateRequired}
                    /></Grid>
                }

                <Grid item xs={4} md={2}>
                    <DateInput source={"legalStatusDate"} label={"resources.trainings.fields.legalStatusDate"}
                               initialValue={new Date()}/>
                </Grid></Grid>
            <Grid container spacing={1} style={{width: "100%",}}>
                <Grid item xs={4} md={2}><BooleanInput label={"resources.trainings.fields.archive"} source={"archive"}/></Grid>
                <Grid item xs={4} md={2}><BooleanInput label={"resources.trainings.fields.active"}
                                                       source={"active"}/></Grid>
            </Grid>
            <Grid container spacing={1} style={{width: "100%",}}>
                <Grid item xs={12} md={6}>
                    <ReferenceArrayInput
                        label="Patronages"
                        reference="patronages"
                        source="patronage"
                        fullWidth
                        perPage={200}
                        format={v => {
                            if (!v) {
                                return;
                            }
                            for (let i = 0; i < v.length; i++) {
                                if (typeof v[i] === 'object') {
                                    v[i] = v[i]['@id'];
                                }
                            }
                            return v;
                        }}
                    >
                        <AutocompleteArrayInput optionText={(choice) => {
                            if (typeof choice?.name !== 'undefined') {
                                return `${choice.name}`
                            }
                        }}/>
                    </ReferenceArrayInput>
                </Grid>
            </Grid>
        </FormTab>
    );
};

export const TrainingsVideosFormTab = ({children, ...rest}) => {
    const classes = useStyles();
    return (
        <FormTab label={"Videos"} {...rest}>
            <ArrayInput source="videos" reference="videos">
                <SimpleFormIterator classes={{line: classes.line}}>
                    <FormDataConsumer>
                        {({
                              formData, // The whole form data
                              scopedFormData, // The data for this item of the ArrayInput
                              getSource, // A function to get the valid source inside an ArrayInput
                              ...rest
                          }) => {
                            return (
                                scopedFormData && scopedFormData.vimeoId ? (
                                    <Fragment>
                                        <VimeoIframe
                                            source={getSource('vimeoId')}
                                            vimeoId={scopedFormData.vimeoId}
                                            classes={classes}
                                        />
                                        <VimeoVideoLengthInput
                                            vimeoId={scopedFormData.vimeoId}
                                            source={getSource('length')}
                                        />
                                    </Fragment>
                                ) : null
                            )
                        }}
                    </FormDataConsumer>
                    <TextInput source="title" validate={validateTitleVideo}
                               label={"resources.trainings.fields.videos.title"} fullWidth/>
                    <NumberInput source="vimeoId" validate={validateVimeoId}
                                 label={"resources.trainings.fields.videos.vimeoId"} fullWidth/>
                    <TextInput source="vimeoUrl"
                               label={"resources.trainings.fields.videos.vimeoUrl"} fullWidth/>
                    <BooleanInput
                        source="paid"
                        label={"resources.trainings.fields.videos.paid"}
                    />
                    <NumberInput source="sort" className={classes.sortInput}
                                 label={"resources.trainings.fields.videos.sort"} validate={validateSortVideo}/>
                </SimpleFormIterator>
            </ArrayInput>
            {children}
        </FormTab>
    );
};

export const TrainingsContentsFormTab = ({...rest}) => (
    <FormTab label="Content" {...rest}>
        <RichTextInput source={"shortDescription"} fullWidth/>
        <ArrayInput source={"trainingsContents"} label="">
            <SimpleFormIterator key={"id"}>
                <TrainingsContentsTypes source={"type"} validate={validateContentType}/>
                <RichTextInput source={"content"} validate={validateContentText}/>
            </SimpleFormIterator>
        </ArrayInput>
    </FormTab>
);

export const WebinarsContentsFormTab = ({trainingType, ...rest}) => (
    <FormTab label="Content" {...rest}>
        <RichTextInput source={"shortDescription"} fullWidth/>

        <ArrayInput source={"trainingsContents"} label="">
            <SimpleFormIterator key={"id"}>
                <TrainingsContentsTypes source={"type"} validate={validateContentType} {...rest}
                                        trainingType={trainingType}/>
                <RichTextInput source={"content"} validate={validateContentText}/>
            </SimpleFormIterator>
        </ArrayInput>
    </FormTab>
);

export const TrainingsMediaFormTab = ({children, ...rest}) => (
    <FormTab label="Media" {...rest}>
        <ImageInput
            fullWidth
            source="imageFile"
            label="resources.trainings.fields.image"
            accept=".jpg,.jpeg,.png,.webp,.gif"
            placeholder={<p>Dodaj zdjęcie</p>}
            multiple={false}
        >
            <ImageField source="imageFile" title="title"/>
        </ImageInput>
        {children}
    </FormTab>
);

export const TrainingsAddonsFormTab = ({...rest}) => {
    const translate = useTranslate();
    return (
        <FormTab label="Addons" {...rest}>
            <ReferenceInput
                source="certificate"
                reference="certificates"
                label={translate("resources.trainings.fields.certificate")}
                sort={{field: 'name', order: 'ASC'}}
                fullWidth
                format={v => {
                    if (!v) {
                        return null;
                    }
                    return typeof (v) === 'object' ? v['@id'] : v;
                }}
            >
                <SelectInput
                    helperText="Type describes when user get a certificate"
                    optionText={(record) => `${record.name} (type: ${record.type})`}
                />
            </ReferenceInput>
            <ReferenceInput source="quiz" reference="quizzes"
                            fullWidth
                            perPage={0}
                            filter={{
                                "exists[product]": false,
                                pagination: false
                            }}
                            format={v => {
                                if (!v) {
                                    return null;
                                }
                                return typeof (v) === 'object' ? v['@id'] : v;
                            }}
            >
                <SelectInput optionText="name"/>
            </ReferenceInput>
            <CMSTree {...rest} />
        </FormTab>
    )
};

export const WebinarAddonsFormTab = ({...rest}) => {
    const translate = useTranslate();
    return (
        <FormTab label="Addons" {...rest}>
            <ReferenceInput
                source="certificate"
                reference="certificates"
                label={translate("resources.trainings.fields.certificate")}
                sort={{field: 'name', order: 'ASC'}}
                fullWidth
                format={v => {
                    if (!v) {
                        return null;
                    }
                    return typeof (v) === 'object' ? v['@id'] : v;
                }}
            >
                <SelectInput
                    helperText="Type describes when user get a certificate"
                    optionText={(record) => `${record.name} (type: ${record.type})`}
                />
            </ReferenceInput>
            <CMSTree {...rest} />
        </FormTab>
    )
};

export const TrainingsAdsFormTab = ({...rest}) => (
    <FormTab label="Ads" {...rest}>
        <ReferenceArrayInput
            label="Ads"
            reference="ads"
            source="ads"
            fullWidth
            perPage={0}
            filter={{pagination: false}}
            format={v => {
                if (!v) {
                    return;
                }
                for (let i = 0; i < v.length; i++) {
                    if (typeof v[i] === 'object') {
                        v[i] = v[i]['@id'];
                    }
                }
                return v;
            }}
        >
            <AutocompleteArrayInput
                optionText={(choice) => {
                    if (typeof choice?.name !== 'undefined') {
                        return `[${choice.originId}] ${choice.name}`;
                    }
                }}
            />
        </ReferenceArrayInput>
    </FormTab>
);

export const TrainingsTimetableFormTab = ({...rest}) => {

    const compareDates = (record) => {
        if (record.eventStartDate > record.eventEndDate) {
            return false;
        }
        return undefined;
    }

    const validateFromBeforeTo = (scopedFormData, compareFieldName) => {
        return (value, allValues) => {
            if (value === undefined || scopedFormData === undefined || scopedFormData[compareFieldName] === undefined)
                return undefined;

            const fieldDate = scopedFormData[compareFieldName];

            const message = "ra.validation.start.before.end";
            switch (compareFieldName) {
                case 'eventStartDate':
                    if (new Date(value) < new Date(fieldDate)) {
                        return message;
                    }
                    break;
                default:
                    if (new Date(value) > new Date(fieldDate)) {
                        return message;
                    }
            }

            return undefined;
        };
    };

    const composeValidate = (scopedFormData, compareFieldName) => [
        required(),
        validateFromBeforeTo(scopedFormData, compareFieldName)
    ];

    /**
     * Fix funkcji domyslnej ktora zamienia format datetime yyyy-MM-ddThh:mm na
     * Tue Aug 23 2022 18:22:49 GMT+0200 (czas środkowoeuropejski letni),
     * w rezultacie kazda data ma -2h podczas przesyłnia do API
     * @param {string} value Date string, formatted as yyyy-MM-ddThh:mm
     * @returns {string|Date}
     */
    const parseDateTime = (value: string) => {
        const dateTimeRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}$/;
        if (dateTimeRegex.test(value)) {
            return value;
        }
        return (value ? new Date(value).toISOString() : value);
    }

    return (
        <FormTab label="Timetables" {...rest} >
            <ArrayInput source={"timetable"} label={"resources.trainings.fields.timetable"}>
                <SimpleFormIterator disableReordering>
                    <FormDataConsumer>
                        {({getSource, scopedFormData}) => {
                            return (
                                <Grid container spacing={1} style={{width: "100%"}}>
                                    <Grid item md={1} sm={12}>
                                        <BooleanInput source={getSource("active")} record={scopedFormData}
                                                      label={"resources.trainings.fields.timetables.active"}/>
                                    </Grid>
                                    <Grid item md={2} sm={12}>
                                        <DateTimeInput source={getSource("eventStartDate")} record={scopedFormData}
                                                       label={"resources.trainings.fields.timetables.start"}
                                                       validate={composeValidate(scopedFormData, 'eventEndDate')}
                                                       parse={parseDateTime}
                                        />
                                    </Grid>
                                    <Grid item md={2} sm={12}>
                                        <DateTimeInput source={getSource("eventEndDate")} record={scopedFormData}
                                                       label={"resources.trainings.fields.timetables.end"}
                                                       validate={composeValidate(scopedFormData, 'eventStartDate')}
                                                       parse={parseDateTime}
                                        />
                                    </Grid>
                                    <Grid item md={7} sm={12}>
                                        <TextInput source={getSource("description")} validate={validateRequired}
                                                   record={scopedFormData}
                                                   label={"resources.trainings.fields.timetables.description"}/>
                                    </Grid>
                                </Grid>
                            );
                        }}
                    </FormDataConsumer>
                </SimpleFormIterator>
            </ArrayInput>
        </FormTab>
    );
};

export const WebinarsStreamFormTab = ({children, ...rest}) => {
    const classes = useStyles();
    const [limitReached, setLimitReached] = useState(rest.record !== undefined && rest.record.videos !== undefined ? rest.record.videos.length == 1 : false);

    return (
        <FormTab label={"Streaming"} {...rest} validate={[required()]}>
            <ArrayInput source="videos" reference="videos" validate={[required()]}
                        label={"resources.trainings.fields.stream.videos"}>
                <SimpleFormIterator disableReordering disableAdd={limitReached} disableRemove>
                    <FormDataConsumer>
                        {({
                              formData, // The whole form data
                              scopedFormData, // The data for this item of the ArrayInput
                              getSource, // A function to get the valid source inside an ArrayInput
                              ...rest
                          }) => {
                            setLimitReached(formData.videos.length == 1);
                            if (scopedFormData) {
                                scopedFormData.sort = 0;
                                scopedFormData.paid = true;
                            }
                            {
                                return scopedFormData && (scopedFormData.vimeoId || scopedFormData.vimeoUrl) ? (
                                    <Fragment>
                                        <VimeoVideoLoader
                                            videoId={scopedFormData.vimeoId}
                                            videoUrl={scopedFormData.vimeoUrl}
                                            classes={classes}
                                            sourceVideoId={getSource('vimeoId')}
                                            sourceDuration={getSource('length')}
                                            sourceTitle={getSource('title')}
                                            width={320}
                                            height={180}
                                        />
                                    </Fragment>
                                ) : null;
                            }
                        }}
                    </FormDataConsumer>
                    <FormDataConsumer>
                        {({
                              formData, // The whole form data
                              scopedFormData, // The data for this item of the ArrayInput
                              getSource, // A function to get the valid source inside an ArrayInput
                              ...rest
                          }) => {


                            /**
                             * Czyszczenie pól eventem
                             * Setter .value= is not working as we wanted because React library overrides input value setter
                             * @param field
                             */
                            const handleVimeoChange = (field) => {
                                let input = document.getElementsByName(field)[0];
                                if (input) {
                                    let nativeInputValueSetter = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, "value").set;
                                    nativeInputValueSetter.call(input, '');

                                    let clearEvent = new Event('input', {bubbles: true});
                                    input.dispatchEvent(clearEvent);
                                }
                            };

                            return (
                                <>
                                    <NumberInput source={getSource('vimeoId')}
                                                 validate={validateVimeoId}
                                                 label={"resources.trainings.fields.stream.vimeoId"}
                                                 onChange={(event) => handleVimeoChange(getSource('vimeoUrl'))}
                                                 fullWidth/>
                                    <TextInput source={getSource('vimeoUrl')}
                                               validate={validateVimeoUrl}
                                               label={"resources.trainings.fields.stream.vimeoUrl"}
                                               onChange={(event) => handleVimeoChange(getSource('vimeoId'))}
                                               fullWidth/>
                                </>
                            );
                        }}

                    </FormDataConsumer>
                </SimpleFormIterator>
            </ArrayInput>
        </FormTab>
    );
};