import JWTManager from "../utils/JWTManager";

const entrypoint = process.env.REACT_APP_API_ENTRYPOINT;

export default function getAdsTypes() {
    return fetch(`${entrypoint}/ads/zone-types`, {
        method: 'GET',
        mode: 'cors',
        headers: {
            'Authorization': `Bearer ${JWTManager.getToken()}`
        }
    });
}
