import React from "react";
import {
    TabbedShowLayout, Tab, RichTextField, FunctionField, Datagrid, ArrayField, TextField,
    BooleanField, NumberField, ImageField
} from 'react-admin';
import {
    ShowGuesser,
    FieldGuesser
} from "@api-platform/admin";
import VimeoIframe from "../../components/addons/VimeoIframe";

const TrainingTitle = ({record}) => {
    return <span>Szkolenie {`"${record.name}"`} ({record.id})</span>;
};

const TrainingsShow = (props) => (
    <ShowGuesser title={<TrainingTitle/>} {...props}>
        <TabbedShowLayout>
            <Tab label="General">
                <TextField source="name"/>
                <TextField source="subType"/>
                <ArrayField source="categories" fieldKey="@id">
                    <Datagrid>
                        <FieldGuesser source="name"/>
                    </Datagrid>
                </ArrayField>
                <ArrayField source="experts" fieldKey="@id" basePath="/experts">
                    <Datagrid>
                        <FunctionField label="Full name" render={record => `${record.name} ${record.surname}`}/>
                    </Datagrid>
                </ArrayField>
                <FieldGuesser source="legalStatusDate" addLabel={true} options={{
                    weekday: 'long',
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric'
                }}/>
                <BooleanField source="active"/>
                <FieldGuesser source="createdAt" addLabel={true} options={{
                    weekday: 'long',
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric'
                }}/>
                <FieldGuesser source="updatedAt" addLabel={true} options={{
                    weekday: 'long',
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric'
                }}/>
            </Tab>
            <Tab label="Content">
                <RichTextField source="shortDescription" fullWidth/>
                <ArrayField source="trainingsContents">
                    <Datagrid>
                        <TextField source="type"/>
                        <RichTextField source="content"/>
                    </Datagrid>
                </ArrayField>
            </Tab>
            <Tab label="Videos">
                <ArrayField source="videos" reference="videos">
                    <Datagrid>
                        <VimeoIframe/>
                        <TextField source="title"/>
                        <TextField source="url"/>
                        <BooleanField source="paid"/>
                        <NumberField source="sort"/>
                    </Datagrid>
                </ArrayField>
            </Tab>
            <Tab label="Media">
                <ImageField source="imageUrl" title="title" fullWidth/>
            </Tab>
            <Tab label="Statistics">
                <ArrayField source="trainingsStatistics">
                    <Datagrid>
                        <TextField source="saved"/>
                        <TextField source="during"/>
                        <TextField source="watched"/>
                        <TextField source="completed"/>
                    </Datagrid>
                </ArrayField>
            </Tab>
        </TabbedShowLayout>
    </ShowGuesser>
);

export default TrainingsShow;