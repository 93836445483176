import React from "react";
import {Toolbar, SaveButton} from 'react-admin';

const QuizzesFormToolbar = props => (
    <Toolbar {...props}>
        <SaveButton
            submitOnEnter={true}
            pristine={true}
            saving={true}
            label="Zapisz"
            transform={data => ({
                ...data,
                product: data.product === null ? null : typeof (data.product) === "object" ? data.product['@id'] : data.product,
            })}
        />
    </Toolbar>
);

export default QuizzesFormToolbar;