import React , { useEffect, useState } from "react";
import {
    Edit,
    TabbedForm,
    ImageField,
    useNotify, useRefresh, useRedirect
} from 'react-admin';
import TrainingsFormToolbar from "../../components/toolbars/TrainingsFormToolbar";
import config from "../../config/config.json";
import {
    TrainingsContentsFormTab,
    TrainingsGeneralFormTab,
    TrainingsMediaFormTab,
    TrainingsVideosFormTab,
    TrainingsSEOFormTab,
    TrainingsTranscriptionFormTab,
    TrainingsAddonsFormTab,
    TrainingsAdsFormTab, WebinarsContentsFormTab, TrainingsTimetableFormTab, WebinarsStreamFormTab, WebinarAddonsFormTab
} from "./TrainingsForms";
import {getReturnUrl} from "../../utils/utils";

const TrainingsEdit = props => {
    const notify = useNotify();
    const refresh = useRefresh();
    const redirect = useRedirect();
    const [trainingType, setTrainingType] = useState(props.subType === undefined? 'online':props.subType);

    const onSuccess = ({data}) => {
        notify(`resources.trainings.actions.saveSuccess`, 'info');
        if (getReturnUrl('Trainings')) {
            window.location.href = getReturnUrl('Trainings')
        } else {
            redirect('/trainings');
        }

        refresh();
    };
    return (
      <Edit onSuccess={onSuccess} undoable={false} {...props}>
          <TabbedForm toolbar={<TrainingsFormToolbar/>} variant={config.form.variant} margin={config.form.margin}>
              <TrainingsGeneralFormTab setTrainingType={setTrainingType} trainingType={trainingType} isEdit={true}/>
              {trainingType !== 'webinar' && trainingType !== 'webinarPaid' &&
                  <TrainingsContentsFormTab/>
              }
              {(trainingType === 'webinar' || trainingType === 'webinarPaid') &&
                  <WebinarsContentsFormTab trainingType={trainingType}/>
              }
              {(trainingType === 'webinar' || trainingType === 'webinarPaid') &&
                  <WebinarsStreamFormTab/>
              }
              {trainingType !== 'webinar' && trainingType !== 'webinarPaid' &&
                  <TrainingsVideosFormTab/>
              }
              {(trainingType === 'webinar' || trainingType === 'webinarPaid') &&
                  <TrainingsTimetableFormTab/>
              }
              <TrainingsMediaFormTab>
                  <ImageField source="imageUrl" title="title" fullWidth/>
              </TrainingsMediaFormTab>
              <TrainingsSEOFormTab/>
              {trainingType !== 'webinar' && trainingType !== 'webinarPaid' &&
                  <TrainingsTranscriptionFormTab/>
              }
              {trainingType !== 'webinar' && trainingType !== 'webinarPaid' &&
                  <TrainingsAddonsFormTab/>
              }
              {(trainingType === 'webinar' || trainingType === 'webinarPaid') &&
                  <WebinarAddonsFormTab/>
              }
              <TrainingsAdsFormTab/>
          </TabbedForm>
      </Edit>
    )
}

export default TrainingsEdit;