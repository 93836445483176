import React from "react";
import {
    FormTab,
    TextInput,
    ImageInput,
    ImageField,
    required
} from 'react-admin';
import RichTextInput from "ra-input-rich-text";
import { Grid } from '@material-ui/core';
import BannersTypes from "../../components/fields/BannersTypes";
const validateName = [required()];

export const BannersGeneralFormTab = ({...rest}) => {
    return (
        <FormTab label="General" {...rest}>

            <Grid container spacing={1} style={{ width: "100%"}}>
                <Grid item xs={12}>
                    <TextInput label="resources.banners.fields.title" source="title" fullWidth validate={validateName}/>
                </Grid>
            </Grid>
            <Grid container spacing={1} style={{ width: "100%"}}>
                <Grid item xs={12} md>
                    <RichTextInput label="resources.banners.fields.content" source="content" fullWidth validate={validateName}/>
                </Grid>
            </Grid>
            <Grid container spacing={1} style={{ width: "100%"}}>
                <Grid item xs={12} md={2}>
                    <TextInput label="resources.banners.fields.buttonName" source="buttonName" fullWidth validate={validateName}/>
                </Grid>
                <Grid item xs={12} md>
                    <TextInput label="resources.banners.fields.uri"  source="uri" fullWidth validate={validateName}/>
                </Grid>
                <Grid item xs={12} md>

                    <ImageInput
                        fullWidth
                        source="imageFile"
                        label="resources.banners.fields.image"
                        accept=".jpg,.jpeg,.png,.webp,.gif"
                        placeholder={<p>Dodaj zdjęcie</p>}
                        multiple={false}
                    >
                        <ImageField source="imageFile" title="title" />
                    </ImageInput>
                </Grid>
                <Grid item xs={12} md={2}>
                    <BannersTypes fullWidth source={"type"} label="resources.banners.fields.type" fullWidth validate={validateName}/>
                </Grid>
            </Grid>
        </FormTab>
    );
};