import * as React from "react";
import {
    BooleanField, TextField, ArrayField, List as AdminList, Datagrid, EditButton, FunctionField, SingleFieldList
} from 'react-admin';
import gravatar from 'gravatar';
import SimpleChipField from "../../components/fields/SimpleChipField";

const AuthsList = props => {
    return (
        <AdminList
            {...props}
            title="Auths"
            sort={{field: 'username', order: 'ASC'}}
        >
            <Datagrid>
                <FunctionField label="Avatar" render={record => {
                    return <img src={gravatar.url(record.email, {d: 'robohash'})} alt={record.username}/>;
                }} />
                <TextField source="username"/>
                <TextField source="email"/>
                <ArrayField source="roles">
                    <SingleFieldList>
                        <SimpleChipField variant="outlined"/>
                    </SingleFieldList>
                </ArrayField>
                <BooleanField source="active"/>
                <EditButton />
            </Datagrid>
        </AdminList>
    )
};

export default AuthsList;