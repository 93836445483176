import React from "react";
import {TabbedForm, Edit, ImageField} from 'react-admin';
import {
    PatronagesGeneralFormTab,
    PatronagesTrainingsFormTab,
    PatronagesMediaFormTab
} from "./PatronagesForms";
import config from "../../config/config.json";

const PatronagesEdit = (props) => {
    return (
        <Edit {...props}>
            <TabbedForm variant={config.form.variant} margin={config.form.margin}>
                <PatronagesGeneralFormTab />
                <PatronagesTrainingsFormTab />
                <PatronagesMediaFormTab>
                    <ImageField source="imageUrl" fullWidth />
                </PatronagesMediaFormTab>
            </TabbedForm>
        </Edit>
    );
};

export default PatronagesEdit;