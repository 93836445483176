import React, {useState, useEffect} from 'react';
import {SelectInput, useNotify, LinearProgress} from 'react-admin';
import getBannersTypes from "../../api/getBannersTypes";

function BannersTypes(props) {
    const notify = useNotify();
    const [bannersTypes, setBannersTypes] = useState(null);

    useEffect(() => {
        getBannersTypes()
            .then(response => {
                if (!response.ok) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then(data => {
                setBannersTypes(data.map(item => {
                    return {
                        id: item.type,
                        name: item.name
                    }
                }));
            })
            .catch(e => notify('Error: problem with fetch trainings types', 'warning'));
    }, [notify, setBannersTypes]);

    if (bannersTypes === null) {
        return <LinearProgress/>;
    }

    return (
        <SelectInput
            {...props}
            choices={bannersTypes}
        />
    );
}

export default BannersTypes;