import React from 'react';
import ShowGuesser from "@api-platform/admin/lib/ShowGuesser";
import FieldGuesser from "@api-platform/admin/lib/FieldGuesser";
import { SingleFieldList, ChipField, Datagrid, TextField, EditButton, ReferenceManyField, ArrayField, RichTextField, ImageField } from 'react-admin';

const TestimonialsShow = props => (
    <ShowGuesser {...props} >
        <FieldGuesser source={"author"} addLabel={true} />
        <FieldGuesser source={"authorPosition"} addLabel={true} />
        <FieldGuesser source={"opinion"} addLabel={true} />
        <FieldGuesser source={"sort"} addLabel={true} />
        <FieldGuesser source={"active"} addLabel={true} />
        <FieldGuesser source={"createdAt"} addLabel={true} />
        <FieldGuesser source={"updatedAt"} addLabel={true} />
    </ShowGuesser>
);

export default TestimonialsShow;