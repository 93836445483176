import React from 'react';
import ShowGuesser from "@api-platform/admin/lib/ShowGuesser";
import FieldGuesser from "@api-platform/admin/lib/FieldGuesser";
import { SingleFieldList, ChipField, Datagrid, TextField, EditButton, ReferenceManyField, ArrayField, RichTextField, ImageField } from 'react-admin';

const TrustedUsShow = props => (
    <ShowGuesser {...props} >
        <ImageField source="imageUrl" label="resources.trusted_us.fields.image" />
        <FieldGuesser source={"name"} addLabel={true} />
        <FieldGuesser source={"alt"} addLabel={true} />
        <FieldGuesser source={"url"} addLabel={true} />
        <FieldGuesser source={"createdAt"} addLabel={true} />
        <FieldGuesser source={"updatedAt"} addLabel={true} />
    </ShowGuesser>
);

export default TrustedUsShow;