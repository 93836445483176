import QuizzesIcon from '@material-ui/icons/ListAlt';
import QuizzesList from './QuizzesList';
import QuizzesCreate from './QuizzesCreate';
import QuizzesEdit from "./QuizzesEdit";
import QuizzesShow from "./QuizzesShow";

export default {
    create: QuizzesCreate,
    edit: QuizzesEdit,
    list: QuizzesList,
    show: QuizzesShow,
    icon: QuizzesIcon,
    options: {
        label: "Quizzes"
    }
};