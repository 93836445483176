import affiliatePackagesIcon from '@material-ui/icons/Unarchive';
import PackageList from "./PackageList";
import PackageCreate from "./PackageCreate";
import {PackagesFormTab} from "./PackageEdit";
import PackageShow from "./PackageShow";

export default {
    icon: affiliatePackagesIcon,
    list: PackageList,
    create: PackageCreate,
    edit: PackagesFormTab,
    show: PackageShow,
    options: {
        dividierDown: true,
        label: "Affiliate codes package"
    }
}