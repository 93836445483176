import AuthsIcon from '@material-ui/icons/PeopleOutline';
import AuthsList from './AuthsList';
import AuthsCreate from "./AuthsCreate";
import AuthsEdit from "./AuthsEdit";
import AuthsShow from "./AuthsShow";

export default {
    list: AuthsList,
    create: AuthsCreate,
    edit: AuthsEdit,
    show: AuthsShow,
    icon: AuthsIcon,
    options: {
        label: "Auths",
        dividerUp: true
    }
};