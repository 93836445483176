import React from "react";
import {
    HydraAdmin,
    ResourceGuesser,
    ListGuesser,
    FieldGuesser
} from "@api-platform/admin";
import CustomLayout from "./layouts/CustomLayout";
import customRoutes from './config/routes';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import raPolishMessages from 'ra-language-polish';
import config from "./config/config.json";
import polishMessages from './translations/messages.pl.json';
import myDataProvider from "./dataProviders/myDataProvider";
import authProvider from "./dataProviders/authProvider";
import categories from "./pages/categories";
import trainings from "./pages/trainings";
import experts from "./pages/experts";
import auths from "./pages/auths";
import certificates from "./pages/certificates";
import subscriptions from "./pages/subscriptions";
import banners from "./pages/banners";
import patronages from "./pages/patronages";
import ads from "./pages/ads";
import quizzes from "./pages/quizzes";
import orders from "./pages/orders";
import Dashboard from "./pages/dashboard/Dashboard";
import Login from "./pages/login/Login";
import appActionsLog from "./pages/appActionsLog";
import affiliatePartners from "./pages/affiliatePartners";
import affiliateCodesPackages from "./pages/affiliateCodes";
import discountCodes from "./pages/discountCodes";
import trustedUs from "./pages/trustedUs";
import testimonials from "./pages/testimonials";
// import webinarPaidStudents from "./pages/webinarPaidStudents";
import { Resource } from 'react-admin';
const raMessages = {'pl': Object.assign(raPolishMessages, polishMessages)};
const i18nProvider = polyglotI18nProvider(locale => raMessages[config.locale]);
const entrypoint = process.env.REACT_APP_API_ENTRYPOINT;

// const GetOrderedWebinarListList = props => (
//     <ListGuesser {...props}>
//         <FieldGuesser source={"list"} />
//     </ListGuesser>
// );
export default () => (
    <HydraAdmin
        entrypoint={ entrypoint }
        dataProvider={ myDataProvider }
        authProvider={ authProvider }
        i18nProvider={ i18nProvider }
        layout={ CustomLayout }
        dashboard={ Dashboard }
        customRoutes={ customRoutes }
        loginPage={Login}
    >
        <ResourceGuesser
            name="trainings"
            {...trainings}
        />
        <ResourceGuesser
            name="experts"
            {...experts}
        />
        <ResourceGuesser
            name="categories"
            {...categories}
        />
        <ResourceGuesser
            name="quizzes"
            {...quizzes}
        />
        <ResourceGuesser
            name="certificates"
            {...certificates}
        />
        <ResourceGuesser
            name="discount_codes"
            {...discountCodes}
        />
        <ResourceGuesser
            name="banners"
            {...banners}
        />
        <ResourceGuesser
            name="ads"
            {...ads}
        />
        <ResourceGuesser
            name="patronages"
            {...patronages}
        />
        <ResourceGuesser
            name="testimonials"
            {...testimonials}
        />
        <ResourceGuesser
            name="trusted_uses"
            {...trustedUs}
        />
        <ResourceGuesser
            name="subscriptions"
            {...subscriptions}
        />
        <ResourceGuesser
            name="orders"
            {...orders}
        />

        {/*<ResourceGuesser name={"support/webinar/paid/list"} list={GetOrderedWebinarListList} />*/}

        <ResourceGuesser
            name="affiliate_partners"
            {...affiliatePartners}
        />
        <ResourceGuesser
            name="affiliate_codes_packages"
            {...affiliateCodesPackages}
        />
        <ResourceGuesser
            name="auths"
            {...auths}
        />
        <ResourceGuesser
            name="app_actions_logs"
            {...appActionsLog}
        />
        {/*Resource dodany zeby nie rysowac pozycji menu z CRUDem a dzialala obsluga wypelniania ReferenceInput*/}
        <Resource
            name="affiliate_partner_mails"
        />
        <Resource
            name="portal_brands"
        />
        {/*<Resource*/}
        {/*    name="webinar_paid"*/}
        {/*/>*/}
    </HydraAdmin>
);