import React from "react";
import {TabbedForm, Edit, ImageField} from 'react-admin';
import {
    TestimonialsGeneralFormTab,
    TestimonialsMediaFormTab
} from "./TestimonialsForms";
import config from "../../config/config.json";


const TestimonialsEdit = (props) => {
    return (
        <Edit {...props}>
            <TabbedForm variant={config.form.variant} margin={config.form.margin}>
                <TestimonialsGeneralFormTab />
            </TabbedForm>
        </Edit>
    );
};

export default TestimonialsEdit;