import React, {useState, useEffect} from 'react';
import {SelectInput, useNotify, LinearProgress} from 'react-admin';
import getTrainingsTypes from "../../api/getTrainingsTypes";

function TrainingsTypes(props,test) {
    const notify = useNotify();
    const [trainingsTypes, setTrainingsTypes] = useState(null);

    useEffect(() => {
        getTrainingsTypes()
            .then(response => {
                if (!response.ok) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then(data => {
                setTrainingsTypes(data.map(item => {
                    return {
                        id: item.type,
                        name: item.name
                    }
                }));
            })
            .catch(e => notify('Error: problem with fetch trainings types', 'warning'));
    }, [notify, setTrainingsTypes]);

    if (trainingsTypes === null) {
        return <LinearProgress/>;
    }

    return (
        <SelectInput
            {...props}
            choices={trainingsTypes}
        />
    );
}

export default TrainingsTypes;