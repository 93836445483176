import React from "react";
import {TabbedForm, Edit} from 'react-admin';
import {OrdersGeneralFormTab} from "./OrdersForms";
import config from "../../config/config.json";

const OrdersEdit = (props) => {
    return (
        <Edit {...props}>
            <TabbedForm variant={config.form.variant} margin={config.form.margin}>
                <OrdersGeneralFormTab />
            </TabbedForm>
        </Edit>
    );
};

export default OrdersEdit;