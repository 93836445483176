import React from "react";
import {
    Edit, TabbedForm
} from 'react-admin';
import config from "../../config/config.json";
import CategoriesFormToolbar from "../../components/toolbars/CategoriesFormToolbar";
import {CategoriesGeneralFormTab, CategoriesTrainingsFormTab, CategoriesSEOFormTab} from "./CategoriesForms";

const CategoriesEdit = props => (
    <Edit {...props} mutationMode="pessimistic" transform={data => {
        if (data.brand && typeof data.brand !== 'string' && data.brand !== undefined) {
            data.brand = data.brand['@id'];
        }
        return data;
    }}>
        <TabbedForm toolbar={<CategoriesFormToolbar />} variant={config.form.variant} margin={config.form.margin}>
            <CategoriesGeneralFormTab />
            <CategoriesTrainingsFormTab />
            <CategoriesSEOFormTab />
        </TabbedForm>
    </Edit>
);

export default CategoriesEdit;