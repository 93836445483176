import React from 'react';
import ListGuesser from "@api-platform/admin/lib/ListGuesser";
import FieldGuesser from "@api-platform/admin/lib/FieldGuesser";
import {
    TextField, TopToolbar,
    CreateButton, BooleanField
} from 'react-admin';


const ListActions = () => (
    <TopToolbar>
        <CreateButton/>
        {/*<CloneButton />*/}
    </TopToolbar>
);

const PartnerList = props => {
    return (
        <ListGuesser {...props} title="Affiliate partner list" sort={{field: 'name', order: 'ASC'}}
                     bulkActionButtons={false}>
            <TextField source={"name"} label="resources.affiliates.fields.partner.name"/>
            <BooleanField source={"active"} label="resources.affiliates.fields.partner.active"/>
            <FieldGuesser source={"createdAt"} label="Created at"/>
            <FieldGuesser source={"updatedAt"} label="Updated at"/>
        </ListGuesser>
    );
};

export default PartnerList;
