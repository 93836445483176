import React from 'react';
import { withStyles  } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import { Box, CardContent, Button, Grid, Paper, Typography } from '@material-ui/core';
import { Title, translate } from 'react-admin';
import HomeIcon from '@material-ui/icons/Home';
import { compose } from '../../utils/utils'

const styles = (theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
});

const  Dashboard = ({classes, translate, spacing=3}) => (
    <div className={classes.root}>
        <Grid container spacing={spacing}>
            <Grid item xs>
                <Box color="text.primary" clone>
                    <Card>
                        <Title title="Welcome to the administration"/>
                        <CardContent>
                            <Typography variant="h5">Lorem ipsum sic dolor amet...</Typography>
                            <Button variant="contained" color="primary">
                                Primary
                            </Button>
                        </CardContent>
                    </Card>
                </Box>
            </Grid>
            <Grid item xs>
                <Paper className={classes.paper}>
                    <Button href="https://akademia.wip.pl">
                        <HomeIcon style={{ paddingRight: '0.5em' }} />
                        {translate('Summary')} XXX
                    </Button>
                </Paper>
            </Grid>
        </Grid>
        <Grid container spacing={spacing}>
            <Grid item xs>
                <Paper className={classes.paper}>xs</Paper>
            </Grid>
            <Grid item xs={6}>
                <Paper className={classes.paper}>xs=6</Paper>
            </Grid>
            <Grid item xs>
                <Paper className={classes.paper}>xs</Paper>
            </Grid>
        </Grid>
    </div>
);


const enhance = compose(
    withStyles(styles),
    translate
);

export default enhance(Dashboard);