import { createMuiTheme } from '@material-ui/core/styles';

export const mainTheme = createMuiTheme({
    typography: {
        fontFamily: [
            'Roboto',
            '"Segoe UI"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
            '-apple-system',
            '"Apple Color Emoji"',
            'Arial',
            'sans-serif'
        ].join(','),
    },
    palette: {
        primary: {
            light: '#58a5ef',
            main: '#0277bd',
            dark: '#004c8b',
            contrastText: '#fff',
        },
        secondary: {
            light: '#819ca9',
            main: '#538ed0',
            dark: '#154558',
            contrastText: '#fff',
        },
    },
});