import React from "react";
import {TabbedForm, Create} from 'react-admin';
import {TrustedUsGeneralFormTab, TrustedUsMediaFormTab} from "./TrustedUsForms";
import config from "../../config/config.json";

const TrustedUsCreate = (props) => {
    return (
        <Create {...props} title="Add TrustedUs">
            <TabbedForm variant={config.form.variant} margin={config.form.margin}>
                <TrustedUsGeneralFormTab />
                <TrustedUsMediaFormTab />
            </TabbedForm>
        </Create>
    );
};

export default TrustedUsCreate;