import React, {useState, useEffect} from 'react';
import {SelectInput, useNotify, LinearProgress} from 'react-admin';
import getAdsTypes from "../../api/getAdsTypes";

function AdsTypes(props) {
    const notify = useNotify();
    const [AdsTypes, setAdsTypes] = useState(null);

    useEffect(() => {
        getAdsTypes()
            .then(response => {
                if (!response.ok) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then(data => {
                setAdsTypes(data.map(item => {
                    return {
                        id: item.type,
                        name: item.name
                    }
                }));
            })
            .catch(e => notify('Error: problem with fetch ads types', 'warning'));
    }, [notify, setAdsTypes]);

    if (AdsTypes === null) {
        return <LinearProgress/>;
    }

    return (
        <SelectInput
            {...props}
            choices={AdsTypes}
        />
    );
}

export default AdsTypes;