import CodesIcon from '@material-ui/icons/MoneyOff';
 import DiscountCodesEdit from "./CodeEdit";
 import DiscountCodesList from "./CodeList";
 import DiscountCodesCreate from "./CodeCreate";
 // import DiscountCodesShow from "./";

export default {
      edit: DiscountCodesEdit,
     create: DiscountCodesCreate,
    list: DiscountCodesList,
     // show: CodesShow,
    icon: CodesIcon,
    options: {
        label: "Discount codes"
    }
};