import React from "react";
import PropTypes from 'prop-types';

const VimeoIframe = (params) => {
    let iframeVideoContainerClass = typeof (params.classes) !== "undefined" ? params.classes.iframeVideoContainer : null;
    let vimeoId = typeof (params.vimeoId) !== "undefined" ? params.vimeoId : params.record.vimeoId;
    return (
        <div className={iframeVideoContainerClass}>
            <iframe
                src={`https://player.vimeo.com/video/${vimeoId}`}
                frameBorder="0"
                allow="autoplay; fullscreen"
                width="320"
                height="180"
                allowFullScreen
                title={`video-${vimeoId}`}
            />
        </div>
    );
}

VimeoIframe.propTypes = {
    url: PropTypes.string
}

export default VimeoIframe;