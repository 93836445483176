import React from "react";
import {TabbedForm, Edit} from 'react-admin';
import config from "../../config/config.json";
import {
    QuizzesGeneralFormTab,
} from "./QuizzesForms";
import QuizzesFormToolbar from "../../components/toolbars/QuizzesFormToolbar";

const QuizzesEdit = props => (
    <Edit {...props}>
        <TabbedForm toolbar={<QuizzesFormToolbar />} variant={config.form.variant} margin={config.form.margin}>
            <QuizzesGeneralFormTab />
        </TabbedForm>
    </Edit>
);

export default QuizzesEdit;