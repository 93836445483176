import React from 'react';
import ListGuesser from "@api-platform/admin/lib/ListGuesser";
import FieldGuesser from "@api-platform/admin/lib/FieldGuesser";
import { TextField, SingleFieldList, ChipField, ArrayField, BooleanField, ImageField } from 'react-admin';

const TestimonialsList = props => {
    return (
        <ListGuesser {...props} title="TrustedUs list" sort={{ field: 'name', order: 'ASC' }}>
            <TextField source={"author"} label="resources.testimonials.fields.author" />
            <TextField source={"authorPosition"} label="resources.testimonials.fields.author_position" />
            <TextField source={"opinion"} label="resources.testimonials.fields.opinion" />
            <FieldGuesser source={"sort"} label="resources.testimonials.fields.sort" />
            <FieldGuesser source={"active"} label="resources.testimonials.fields.active" />
            <FieldGuesser source={"createdAt"} label="Created at"/>
            <FieldGuesser source={"updatedAt"} label="Updated at"/>
        </ListGuesser>
    );
};

export default TestimonialsList;