import React from 'react';
import ListGuesser from "@api-platform/admin/lib/ListGuesser";
import FieldGuesser from "@api-platform/admin/lib/FieldGuesser";
import {
    TextField,
    BooleanField,
    FunctionField,
    TopToolbar,
    CreateButton,
    Button,
    ReferenceField
} from 'react-admin';
import SaveIcon from '@material-ui/icons/Save';
import affiliatePackageCsvDownload from "../../api/getAffiliatePackage";

const ListActions = () => (
    <TopToolbar>
        <CreateButton/>
        {/*<CloneButton />*/}
    </TopToolbar>
);

export const DownloadCsv = ({basePath, record, ...rest}) => {
    return (
        record.type === 'internal' ?
            <Button
                label="resources.affiliates.fields.package.list.download.btn"
                startIcon={<SaveIcon/>}
                onClick={() => {
                    affiliatePackageCsvDownload({packageId: record.originId})
                        .then(response => {
                            console.log(response)
                        })
                        .catch(error => {
                            console.log(error)
                        });
                }}
            /> : ''
    );
}

const PackageList = props => {
    return (
        <ListGuesser {...props} title="Affiliate Package list" sort={{field: 'id', order: 'DESC'}}
                     bulkActionButtons={false} actions={<ListActions/>}>
            <ReferenceField label="resources.affiliates.fields.package.list.partner" source="partner" reference="affiliate_partners">
                <TextField source="name" />
            </ReferenceField>
            <TextField source={"type"} label="resources.affiliates.fields.package.list.type"/>
            <TextField source={"count"} label="resources.affiliates.fields.package.list.count"/>
            <FunctionField source="code" label="resources.affiliates.fields.package.list.count.left" render={record => {
                let leftCodes = 0;
                for (let i=0;i<record.code.length; i++) {
                    if (record.code[i].dateOfUse === undefined || record.code[i].dateOfUse === null)
                        leftCodes++;
                }
                return `${leftCodes}`
            }
            }/>
            <TextField source={"description"} label="resources.affiliates.fields.package.list.name"/>
            <BooleanField source={"active"} label="resources.affiliates.fields.package.list.active"/>
            <FieldGuesser source={"createdAt"} label="Created at"/>
            <FieldGuesser source={"updatedAt"} label="Updated at"/>
            <DownloadCsv label="resources.affiliates.fields.package.list.download"/>
        </ListGuesser>
    );
};

export default PackageList;
