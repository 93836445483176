import * as React from "react";
import {
    TextField, List as AdminList, Datagrid,
    EditButton, DateField
} from 'react-admin';

const BannersList = props => {
    return (
        <AdminList
             {...props}
             sort={{field: 'createdAt', order: 'DESC'}}
         >
             <Datagrid>
                <TextField source={"title"}/>
                <TextField source={"type"}/>
                <TextField source={"uri"}/>
                <DateField source={"createdAt"} showTime/>
                <DateField source={"updatedAt"} showTime/>
                <EditButton />
             </Datagrid>
        </AdminList>
    )
};

export default BannersList;