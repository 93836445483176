import React, {useEffect, useState} from "react";
import {TabbedForm, Create} from 'react-admin';
import TrainingsFormToolbar from "../../components/toolbars/TrainingsFormToolbar";
import config from "../../config/config.json";
import {
    TrainingsContentsFormTab,
    TrainingsGeneralFormTab,
    TrainingsMediaFormTab,
    TrainingsVideosFormTab,
    TrainingsSEOFormTab,
    WebinarsContentsFormTab,
    TrainingsTimetableFormTab,
    WebinarsStreamFormTab
} from "./TrainingsForms";


const TrainingsCreate = (props) => {
    const [trainingType, setTrainingType] = useState(props.subType === undefined ? 'online' : props.subType);
    return (
        <Create {...props}>
            <TabbedForm toolbar={<TrainingsFormToolbar/>} variant={config.form.variant} margin={config.form.margin}>
                <TrainingsGeneralFormTab setTrainingType={setTrainingType} trainingType={trainingType} isEdit={false}/>
                {trainingType !== 'webinar' && trainingType !== 'webinarPaid' &&
                    <TrainingsContentsFormTab/>
                }
                {(trainingType === 'webinar' || trainingType === 'webinarPaid') &&
                    <WebinarsContentsFormTab trainingType={trainingType}/>
                }
                {(trainingType === 'webinar' || trainingType === 'webinarPaid') &&
                    <WebinarsStreamFormTab/>
                }
                {trainingType !== 'webinar' && trainingType !== 'webinarPaid' &&
                    <TrainingsVideosFormTab/>
                }
                {(trainingType === 'webinar' || trainingType === 'webinarPaid') &&
                    <TrainingsTimetableFormTab/>
                }
                <TrainingsMediaFormTab/>
                <TrainingsSEOFormTab/>
            </TabbedForm>
        </Create>
    )
};

export default TrainingsCreate;