import React from "react";
import {
    FormTab,
    TextInput,
    ImageInput,
    ImageField,
    AutocompleteArrayInput,
    ReferenceArrayInput,
    required,
    maxLength
} from 'react-admin';

import AdsTypes from "../../components/fields/AdsTypes";
import RelTypes from "../../components/fields/RelTypes";

const validateRequired = [required()];
const validateAlt = [];
const validateUrl = [maxLength(2048)];

export const AdsGeneralFormTab = ({...rest}) => {
    return (
        <FormTab label="General" {...rest}>
            <TextInput source={"name"} fullWidth validate={validateRequired}/>
            <AdsTypes source={"zone"} label="resources.ads.fields.zone" fullWidth validate={validateRequired} />
        </FormTab>
    );
};
export const AdsTrainingsFormTab = ({...rest}) => (
    <FormTab label="Trainings" path="trainings" {...rest}>
        <ReferenceArrayInput
            label="Trainings"
            reference="trainings"
            source="trainings"
            filter={{ pagination: false }}
            fullWidth
            format={v => {
                if (!v) {
                    return null;
                }
                for (let i = 0; i < v.length; i++) {
                    if (typeof v[i] === 'object') {
                        v[i] = v[i]['@id'];
                    }
                }
                return v;
            }}
        >
            <AutocompleteArrayInput />
        </ReferenceArrayInput>
    </FormTab>
);

export const AdsMediaFormTab = ({children, ...rest}) => (
    <FormTab label="resources.ads.media" {...rest}>
        <ImageInput
            fullWidth
            source="imageFile"
            label="resources.ads.fields.image"
            accept=".jpg,.jpeg,.png,.webp,.gif"
            placeholder={<p>Dodaj banner</p>}
            multiple={false}
        >
            <ImageField source="imageFile" title="title" />
        </ImageInput>
        {children}
        <TextInput source={"alt"} fullWidth validate={validateAlt}/>
        <TextInput source={"url"} fullWidth validate={validateUrl} type="url" />
        <RelTypes source={"relType"} label="resources.ads.fields.reltype" fullWidth />
    </FormTab>
);

export const AdsJsCodeUrlFormTab = ({...rest}) => (
    <FormTab label="Code" {...rest}>
        <TextInput source={"js"}
                       fullWidth
                       multiline={true}
        />
    </FormTab>
);
