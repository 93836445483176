import React from "react";
import {
    Edit,
    FormTab,
    TabbedForm,
    TextInput,
    BooleanInput,
    ImageField,
    required
} from 'react-admin';
import RichTextInput from "ra-input-rich-text";
import { Grid } from '@material-ui/core';
import TrainingsFormToolbar from "../../components/toolbars/TrainingsFormToolbar";
import config from "../../config/config.json";
import {
    PartnerDataTab
} from "./PartnersForms";
const validateName = [required()];


export const PartnersFormTab = props => {
    return (
        <Edit undoable={false} {...props}>
            <TabbedForm variant={config.form.variant} margin={config.form.margin}>
                <PartnerDataTab />
            </TabbedForm>
        </Edit>
    )
}