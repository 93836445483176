import React, {useEffect, useState} from "react";
import PropTypes from 'prop-types';
import {LinearProgress, useTranslate} from 'react-admin';
import {useForm} from 'react-final-form';
import Typography from '@material-ui/core/Typography';

let script = document.createElement("script");
script.async = true;
script.src = "https://player.vimeo.com/api/player.js";
document.head.appendChild(script);

const VimeoVideoLengthInput = (params) => {
    const form = useForm();
    const [videoLength, setVideoLength] = useState(null);
    const vimeoId = typeof (params.vimeoId) !== "undefined" ? params.vimeoId : params.record.vimeoId;
    const translate = useTranslate();

    useEffect(() => {
        let iframe = document.querySelector(`iframe[title="video-${vimeoId}"]`);
        iframe.onload = function() {
            // eslint-disable-next-line no-undef
            let player = new Vimeo.Player(iframe);
            player.loadVideo(vimeoId).then(id => {
                player.ready().then(() => {
                    player.getDuration().then((data) => {
                        setVideoLength(data);
                        form.change(params.source, data);
                    })
                    .catch(err => console.log(err));
                }).catch(err => console.log(err));
            });
        }
    }, [videoLength, setVideoLength, vimeoId]);

    if (videoLength === null) {
        return (
            <LinearProgress />
        );
    }

    return (
        <Typography component="h3">
            {translate('Video length')}: &nbsp;
            <Typography component="strong" display="inline">{videoLength} {translate('seconds')}</Typography>
        </Typography>
    );
}

VimeoVideoLengthInput.propTypes = {
    vimeoId: PropTypes.number,
    name: PropTypes.string
}

export default VimeoVideoLengthInput;