import React from "react";
import {Toolbar, SaveButton} from 'react-admin';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles({
    saveButton: {
        margin: ' 0 15px 0 0',
    },
});

const TrainingsFormToolbar = props => {
    const classes = useStyles();
     return(
        <Toolbar {...props}>
            <SaveButton
                className ={classes.saveButton}
                submitOnEnter={true}
                pristine={true}
                saving={true}
                label="Zapisz"
                transform={data => {
                    return {
                        ...data,
                        quiz: data.quiz === null ? null : typeof (data.quiz) === "object" ? data.quiz['@id'] : data.quiz,
                        certificate: data.certificate === null ? null : typeof (data.certificate) === "object" ? data.certificate['@id'] : data.certificate,
                        videos: (data.videos === null || data.videos === undefined) ? [] : data.videos.map(video => ({
                            ...video,
                            paid: !video.paid ? false : video.paid
                        }))
                    }
                }}
            />
            <SaveButton
                submitOnEnter={true}
                pristine={true}
                saving={true}
                label="Opublikuj"
                transform={data => {
                    return {
                        ...data,
                        active: data.active = true,
                        quiz: data.quiz === null ? null : typeof (data.quiz) === "object" ? data.quiz['@id'] : data.quiz,
                        certificate: data.certificate === null ? null : typeof (data.certificate) === "object" ? data.certificate['@id'] : data.certificate,
                        videos: (data.videos === null || data.videos === undefined) ? [] : data.videos.map(video => ({
                            ...video,
                            paid: !video.paid ? false : video.paid
                        }))
                    }
                }}
            />
        </Toolbar>
    )
}

export default TrainingsFormToolbar;