import CertificatesIcon from '@material-ui/icons/CardGiftcard';
import CertificatesList from './CertificatesList';
import CertificatesCreate from './CertificatesCreate';
import CertifcatesEdit from './CertificatesEdit';

export default {
    create: CertificatesCreate,
    edit: CertifcatesEdit,
    list: CertificatesList,
    icon: CertificatesIcon,
    options: {
        label: "Certificates"
    }
};