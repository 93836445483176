import React from 'react';
import ListGuesser from "@api-platform/admin/lib/ListGuesser";
import FieldGuesser from "@api-platform/admin/lib/FieldGuesser";
import { TextField, SingleFieldList, ChipField, ArrayField, BooleanField, ImageField } from 'react-admin';

const TrustedUsList = props => {
    return (
        <ListGuesser {...props} title="TrustedUs list" sort={{ field: 'name', order: 'ASC' }}>
            <ImageField source="imageUrl" label="resources.trusted_us.fields.image" />
            <TextField source={"name"} label="resources.trusted_us.fields.name" />
            <FieldGuesser source={"sort"} label="resources.trusted_us.fields.sort" />
            <FieldGuesser source={"active"} label="resources.trusted_us.fields.active" />
            <FieldGuesser source={"createdAt"} label="Created at"/>
            <FieldGuesser source={"updatedAt"} label="Updated at"/>
        </ListGuesser>
    );
};

export default TrustedUsList;