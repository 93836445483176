import React from "react";
import {TabbedForm, Create} from 'react-admin';
import config from "../../config/config.json";
import {
    QuizzesGeneralFormTab
} from "./QuizzesForms";
import QuizzesFormToolbar from '../../components/toolbars/QuizzesFormToolbar';

const QuizzesCreate = props => (
    <Create {...props}>
        <TabbedForm toolbar={<QuizzesFormToolbar />} variant={config.form.variant} margin={config.form.margin}>
            <QuizzesGeneralFormTab />
        </TabbedForm>
    </Create>
);

export default QuizzesCreate;