import affiliatePartnersIcon from '@material-ui/icons/Share';
import PartnerList from "./PartnerList";
import PartnerCreate from "./PartnerCreate";
import {PartnersFormTab} from "./PartnersEdit";

export default {
    icon: affiliatePartnersIcon,
    list: PartnerList,
    create: PartnerCreate,
    edit: PartnersFormTab,
    options: {
        dividerUp: true,
        dividierDown: true,
        label: "Affiliate partners"
    }
}