import React , { useEffect, useState }from "react";
import {
    Edit,
    FormTab,
    TabbedForm,
    TextInput,
    BooleanInput,
    ImageField,
    required,
    FormDataConsumer,useNotify, useRedirect
} from 'react-admin';
import RichTextInput from "ra-input-rich-text";
import { Grid } from '@material-ui/core';
import TrainingsFormToolbar from "../../components/toolbars/TrainingsFormToolbar";
import config from "../../config/config.json";
import {
    PackageExternalDataTab,
    PackageInternalDataTab, PackageMainTab
} from "./PackageForms";
import VimeoIframe from "../../components/addons/VimeoIframe";
import VimeoVideoLengthInput from "../../components/inputs/VimeoVideoLengthInput";
const validateName = [required()];


export const PackagesFormTab = props => {
    const notify = useNotify();
    const redirect = useRedirect();
    const onFailure = (error) => {

        if (error.response.status == 500) {
            notify('Could not save changes: concurrent edition in progress', 'warning');
        } else {
            notify('ra.notification.http_error', 'warning')
        }
        //redirect('list', props.basePath);
    }
    return (

        <Edit undoable={false} {...props} onFailure={onFailure}>
            <TabbedForm variant={config.form.variant} margin={config.form.margin}>
                <PackageMainTab />
                <FormDataConsumer>
                    {({
                          formData, // The whole form data
                          scopedFormData, // The data for this item of the ArrayInput
                          getSource, // A function to get the valid source inside an ArrayInput
                          ...rest
                      }) => {
                        if (formData.type === 'internal') {
                            // return (<PackageInternalDataTab {...rest}/>)
                        }else {
                            return (<PackageExternalDataTab {...rest}/>)
                        }
                    }}
                </FormDataConsumer>
            </TabbedForm>
        </Edit>
    )
}