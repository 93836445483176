import JWTManager from "../utils/JWTManager";

const entrypoint = process.env.REACT_APP_API_ENTRYPOINT;

 const getCMSTrainingIndex = (trainingId='') => {
    return fetch(`${entrypoint}/cms/get_training_index/${trainingId}`, {
        method: 'GET',
        mode: 'cors',
        headers: {
            'Authorization': `Bearer ${JWTManager.getToken()}`,
            'Content-Type': 'application/json'
        }
    }).then(response => {
        if (!response.ok) {
            throw new Error(response.statusText);
        }
        return response.json();
    });
}

export default getCMSTrainingIndex;