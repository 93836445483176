import React from "react";
import {TabbedForm, Create} from 'react-admin';
import {SubscriptionsGeneralFormTab, SubscriptionsConfigurationFormTab} from "./SubscriptionsForms";
import config from "../../config/config.json";

const SubscriptionsCreate = (props) => {
    return (
        <Create {...props} title="Add expert">
            <TabbedForm variant={config.form.variant} margin={config.form.margin}>
                <SubscriptionsGeneralFormTab />
                <SubscriptionsConfigurationFormTab />
            </TabbedForm>
        </Create>
    );
};

export default SubscriptionsCreate;