import React, {useState, Fragment} from 'react';
import {List, Datagrid, TextField, DateField, NumberField, Filter, SelectArrayInput} from 'react-admin';
import {Card as MuiCard, CardContent, withStyles, makeStyles} from '@material-ui/core';
import AsideLogs from './AsideLogs'
import {DateCreatedGreaterThanFilter} from "../../components/filters";
import config from "../../config/config.json";

const Card = withStyles(theme => ({
    root: {
        [theme.breakpoints.up('sm')]: {
            order: -1,
            width: '20em',
            marginRight: '1em',
        },
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
}))(MuiCard);

const useStyles = makeStyles({
    filterStyle: {
        '& > div': {
            width: '100%',
            margin: '20px 0'
        }
    },
    list: {
        width: '99em'
    }
});

const FiltersSidebar = () => {
    const classes = useStyles();
    return (
        <Card>
            <CardContent>
                <DateCreatedGreaterThanFilter/>
                <Filter className={classes.filterStyle} variant={config.filter.variant}>
                    <SelectArrayInput
                        allowEmpty={true}
                        fullWidth
                        source="group"
                        choices={[
                            {id: 'Order', name: 'Order'},
                            {id: 'AuthWip', name: 'AuthWip'},
                            {id: 'Quiz', name: 'Quiz'},
                            {id: 'Certificate', name: 'Certificate'}
                        ]}
                        alwaysOn
                    />
                </Filter>
                <Filter className={classes.filterStyle} variant={config.filter.variant}>
                    <SelectArrayInput
                        allowEmpty={true}
                        fullWidth
                        source="levelName"
                        choices={[
                            {id: 'DEBUG', name: 'DEBUG'},
                            {id: 'INFO', name: 'INFO'},
                            {id: 'NOTICE', name: 'NOTICE'},
                            {id: 'WARNING', name: 'WARNING'},
                            {id: 'ERROR', name: 'ERROR'},
                            {id: 'CRITICAL', name: 'CRITICAL'},
                            {id: 'ALERT', name: 'ALERT'},
                            {id: 'EMERGENCY', name: 'EMERGENCY'}
                        ]}
                        alwaysOn
                    />
                </Filter>
            </CardContent>
        </Card>
    );
}

const rowStyle = (record, index) => {
    let backgroundColor = null;
    switch (record.levelName) {
        case 'NOTICE':
            backgroundColor = '#fdfff4';
            break;
        case 'WARNING':
            backgroundColor = '#ffbebe';
            break;
        case 'ERROR':
            backgroundColor = '#f93030';
            break;
        case 'CRITICAL':
        case 'ALERT':
        case 'EMERGENCY':
            backgroundColor = '#c11313';
            break;
        case 'DEBUG':
        case 'INFO':
        default:
            backgroundColor = '#fff';
            break;
    }
    return {
        backgroundColor: backgroundColor
    };
};

const AppActionsLogList = (props) => {
    const [aside, setAside] = useState(null);
    const classes = useStyles();
    const showAside = (id) => {
        setAside(<AsideLogs id={id} resource="app_actions_logs" />);
    }

    return (
        <List
            id={props.id}
            resource={props.resource}
            basePath={props.basePath}
            hasCreate={false}
            hasEdit={false}
            hasList={props.hasList}
            hasShow={true}
            sort={{ field: 'createdAt', order: 'DESC' }}
            actions={null}
            perPage={25}
            bulkActionButtons={false}
            aside={
                <Fragment>
                    <FiltersSidebar/>
                    {aside}
                </Fragment>
            }
            className={classes.list}
        >
            <Datagrid rowClick={showAside} rowStyle={rowStyle}>
                <DateField source={"createdAt"} showTime/>
                <TextField source="action" />
                <TextField source="levelName" />
                <NumberField source="userId" />
                <TextField source="group" />
            </Datagrid>
        </List>
    );
}

export default AppActionsLogList;