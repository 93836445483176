import React from "react";
import {TabbedForm, Edit} from 'react-admin';
import {SubscriptionsGeneralFormTab, SubscriptionsConfigurationFormTab} from "./SubscriptionsForms";
import config from "../../config/config.json";

const SubscriptionsEdit = (props) => {
    return (
        <Edit {...props} transform={data => {
            if (data.brand && typeof data.brand !== 'string') {
                data.brand = data.brand['@id'];
            }
            return data;
        }}>
            <TabbedForm variant={config.form.variant} margin={config.form.margin}>
                <SubscriptionsGeneralFormTab/>
                <SubscriptionsConfigurationFormTab/>
            </TabbedForm>
        </Edit>
    );
};

export default SubscriptionsEdit;