import SubscriptionsIcon from '@material-ui/icons/MonetizationOn';
import SubscriptionsEdit from "./SubscriptionsEdit";
import SubscriptionsList from "./SubscriptionsList";
import SubscriptionsCreate from "./SubscriptionsCreate";
import SubscriptionsShow from "./SubscriptionsShow";

export default {
    edit: SubscriptionsEdit,
    create: SubscriptionsCreate,
    list: SubscriptionsList,
    show: SubscriptionsShow,
    icon: SubscriptionsIcon,
    options: {
        label: "Subscriptions",
        dividerUp: true
    }
};