import React from "react";
import {Toolbar, SaveButton} from 'react-admin';

const CategoriesFormToolbar = props => (
    <Toolbar {...props}>
        <SaveButton
            submitOnEnter={true}
            pristine={true}
            saving={true}
            label="Zapisz"
            transform={data => ({
                    ...data,
                    promotedTraining: data.promotedTraining === null ? null : typeof (data.promotedTraining) === "number" ? data.promotedTraining['@id'] : data.promotedTraining,
                    parent: data.parent === null ? null : typeof (data.parent) === "object" ? data.parent['@id'] : data.parent,
                    children: data.children === null ? null : typeof (data.children) === "object" ? data.children.map(item => item['@id']) : data.children,
                    root: data.root === null ? null : typeof (data.root) === "object" ? data.root['@id'] : data.root
            })}
        />
    </Toolbar>
);

export default CategoriesFormToolbar;