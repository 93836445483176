import React from "react";
import {
    TabbedShowLayout, Tab, RichTextField, FunctionField, Datagrid, ArrayField, TextField,
    BooleanField, NumberField, ImageField,SimpleList,DateField
} from 'react-admin';
import {
    ShowGuesser,
    FieldGuesser
} from "@api-platform/admin";

const PackageShow = (props) => (
    <ShowGuesser {...props}>
        <TabbedShowLayout>
            <Tab label="General">
                <TextField source="partner.name"/>
                <TextField source="description"/>
                <TextField source="type"/>
                <BooleanField source="active"/>
            </Tab>
            <Tab label="Partner Codes">
                <ArrayField source="code" fieldKey="@id">
                    <Datagrid rowStyle={record => ({ backgroundColor: record.dateOfUse && record.dateOfUse !== null ? '#ffddcc' : '#ccffcc'})}>
                        <TextField source="code"/>
                        <DateField  source="createdAt" showTime />
                        <DateField  source="dateOfUse" showTime />
                    </Datagrid>
                </ArrayField>
                {/*<SimpleList*/}
                {/*    primaryText={record => record.code}*/}
                {/*    secondaryText={record => `${record.createdAt} views`}*/}
                {/*    tertiaryText={record => new Date(record.dateOfUse).toLocaleDateString()}*/}
                {/*    rowStyle={record => ({ backgroundColor: record.dateOfUse !== null ? '#efe' : 'white' })}*/}
                {/*/>*/}
            </Tab>
        </TabbedShowLayout>
    </ShowGuesser>
);

export default PackageShow;