import React from "react";
import {TabbedForm, Create} from 'react-admin';
import {PatronagesGeneralFormTab, PatronagesTrainingsFormTab, PatronagesMediaFormTab} from "./PatronagesForms";
import config from "../../config/config.json";

const PatronagesCreate = (props) => {
    return (
        <Create {...props} title="Add patronage">
            <TabbedForm variant={config.form.variant} margin={config.form.margin}>
                <PatronagesGeneralFormTab />
                <PatronagesTrainingsFormTab />
                <PatronagesMediaFormTab />
            </TabbedForm>
        </Create>
    );
};

export default PatronagesCreate;