import React from "react";
import {
    Show, SimpleShowLayout, TextField, NumberField, DateField, FunctionField
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    root: {
        width: '30%'
    },
    noActions: {
        marginTop: 0
    },
    codeField: {
        whiteSpace: 'pre'
    }
});

const AsideLogs = (props) => {
    const classes = useStyles();
    return (
        <Show id={props.id} resource={props.resource} basePath={props.basePath} actions={null}  classes={{
            root: classes.root,
            noActions: classes.noActions
        }}>
            <SimpleShowLayout>
                <FunctionField
                    className={classes.codeField}
                    label="resources.app_actions_logs.fields.logData"
                    render={
                        record => record.logData ? JSON.stringify(record.logData, undefined, 2) : '-'
                    }
                />
                <DateField source={"createdAt"} showTime/>
                <TextField source="action" />
                <TextField source="levelName" />
                <NumberField source="userId" />
                <TextField source="group" />
                <TextField source="ipAddress" />
                <TextField source="userAgent" />
            </SimpleShowLayout>
        </Show>
    );
}

export default AsideLogs;