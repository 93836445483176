import React from "react";
import {
    Edit,
    FormTab,
    TabbedForm,
    TextInput,
    BooleanInput,
    ImageField,
    required
} from 'react-admin';
import RichTextInput from "ra-input-rich-text";
import { Grid } from '@material-ui/core';
import TrainingsFormToolbar from "../../components/toolbars/TrainingsFormToolbar";
import config from "../../config/config.json";
import {
    TrainingsAddonsFormTab, TrainingsAdsFormTab,
    TrainingsContentsFormTab,
    TrainingsGeneralFormTab,
    TrainingsMediaFormTab, TrainingsSEOFormTab, TrainingsTranscriptionFormTab,
    TrainingsVideosFormTab
} from "../trainings/TrainingsForms";
const validateName = [required()];


export const PartnerDataTab = ({...rest}) => {
    return (
        <FormTab label="resources.affiliates.tab.partner" {...rest}>
            <Grid container spacing={1} style={{ width: "100%"}}>
                <Grid item xs={12}>
                    <TextInput label="resources.affiliates.fields.partner.name" source="name" fullWidth validate={validateName}/>
                </Grid>
            </Grid>
            <Grid container spacing={1} style={{ width: "100%"}}>
                <Grid item xs={12} md>
                    <BooleanInput label="resources.affiliates.fields.partner.active" source={"active"} />
                </Grid>
            </Grid>
            </FormTab>
    );
};
