import React from "react";
import {
    FormTab,
    TextInput,
    ImageInput,
    ImageField,
    NumberInput,
    BooleanInput,
    AutocompleteArrayInput,
    ReferenceArrayInput,
    required,
    maxLength
} from 'react-admin';
import RelTypes from "../../components/fields/RelTypes";

const validateName = [required()];
const validateRequired = [required()];
const validateUrl = [maxLength(1024)];

export const TestimonialsGeneralFormTab = ({...rest}) =>
    <FormTab label="Content" {...rest}>
        <TextInput source={"opinion"} label="resources.testimonials.fields.opinion" fullWidth validate={validateName}/>
        <TextInput source={"author"} label="resources.testimonials.fields.author" fullWidth validate={validateRequired}/>
        <TextInput source={"authorPosition"} label="resources.testimonials.fields.author_position" fullWidth />
        <NumberInput source={"sort"} label="resources.testimonials.fields.sort" fullWidth validate={validateRequired}/>
        <BooleanInput source={"active"} label="resources.testimonials.fields.active" />
    </FormTab>

