import React from "react";
import {
    AutocompleteInput, FormTab, TextInput, BooleanInput, SelectInput, NumberInput, ArrayInput, SimpleFormIterator, required,ReferenceInput
} from 'react-admin';
import { useInput } from 'react-admin';
import RichTextInput from "ra-input-rich-text";
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';

const validateName = [required()];
const validateAccessLevel = [required()];
const validateMarketingCode = [required()];
const validatePriceNet = [required()];
const validatePriceGross = [required()];
const validateTaxValue = [required()];
const validateSubType = [required()];

export const SubscriptionsGeneralFormTab = ({...rest}) =>
    <FormTab label="Content" {...rest}>
        {/* w <Edit transform={} zmieniamy przed zapisem wartosc brand na uri */}
        <ReferenceInput
            label="resources.subscriptions.fields.brand"
            source="brand"
            reference="portal_brands"
            fullWidth
            perPage={0}
            filter={{ pagination: false }}
            format={(value) => (value && typeof value === 'object' ? value['@id'] : value)}
            parse={(value) => (typeof value === 'object' ? value['@id'] : value)}
        >
            <SelectInput optionText="name"/>
        </ReferenceInput>
        <TextInput source={"name"} fullWidth validate={validateName}/>
        <RichTextInput source="description" />
        <ArrayInput source="benefits">
            <SimpleFormIterator>
                <TextInput label="resources.subscriptions.fields.benefits" fullWidth />
            </SimpleFormIterator>
        </ArrayInput>
        <BooleanInput source={"active"} fullWidth/>
    </FormTab>

export const SubscriptionsConfigurationFormTab = ({...rest}) => {
    return <FormTab label="resources.subscriptions.configuration" {...rest}>
        <NumberInput source="accessLevel" fullWidth validate={validateAccessLevel}/>
        <TextInput source="marketingCodePayu" fullWidth validate={validateMarketingCode}/>
        <TextInput source="marketingCodeInvoice" fullWidth validate={validateMarketingCode}/>
        <SelectInput source="subType" choices={[
            { id: 'month', name: 'resources.subscriptions.fields.type.month' },
            { id: 'quarter', name: 'resources.subscriptions.fields.type.quarter' },
            { id: 'semiAnnual', name: 'resources.subscriptions.fields.type.semiAnnual' },
            { id: 'annual', name: 'resources.subscriptions.fields.type.annual' },
            { id: 'free', name: 'resources.subscriptions.fields.type.free' },
            { id: 'test', name: 'resources.subscriptions.fields.type.test' }
        ]} fullWidth validate={validateSubType}/>
        <NumberInput
            source="priceNet"
            fullWidth
            validate={validatePriceNet}
            InputProps={{
                endAdornment: <InputAdornment position="end">PLN</InputAdornment>,
            }}
        />
        <NumberInput
            source="priceGross"
            fullWidth
            validate={validatePriceGross}
            InputProps={{
                endAdornment: <InputAdornment position="end">PLN</InputAdornment>,
            }}
        />
        <NumberInput source="taxValue" fullWidth validate={validateTaxValue}/>
        <RichTextInput source="additionalPriceInfo" />
    </FormTab>
}