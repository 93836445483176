import JWTManager from "../utils/JWTManager";

const entrypoint = process.env.REACT_APP_API_ENTRYPOINT;
export function csvDownload(filename, text){
    var anchorElement = document.createElement('a');
    anchorElement.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(text));
    anchorElement.setAttribute('download', filename);

    if (document.createEvent) {
        var event = document.createEvent('MouseEvents');
        event.initEvent('click', true, true);
        anchorElement.dispatchEvent(event);
    }
    else {
        anchorElement.click();
    }
}
export default function affiliatePackageCsvDownload({packageId}) {
    let url = `${entrypoint}/affiliate_codes_packages/${packageId}/download`;
    let filename;
    return fetch(url, {
        method: 'GET',
        mode: 'cors',
        headers: {
            'Authorization': `Bearer ${JWTManager.getToken()}`,
            'Content-Type': 'application/json',
            "Accept": "text/csv"
        }
    })
        .then((response) => {
            console.log(response.headers.get("content-disposition"));
            return response.text();
        })
        .then((responseText) => csvDownload(`package-${packageId}.csv`, responseText))
        .catch((error) => {
            console.error(error)
        })
}
