import React from 'react';
import ListGuesser from "@api-platform/admin/lib/ListGuesser";
import FieldGuesser from "@api-platform/admin/lib/FieldGuesser";
import {
    TextField, TopToolbar,DateField,
    CreateButton, BooleanField,FunctionField, useTranslate
} from 'react-admin';
import discountTypes from "./types/discountTypes"

const ListActions = () => (
    <TopToolbar>
        <CreateButton/>
        {/*<CloneButton />*/}
    </TopToolbar>
);

const getDiscountType = (type) => {
    const discountType = discountTypes.find(discount => discount.id === type);
    return discountType ? discountType.name : null;
}

const DiscountCodesList = props => {
    const translate = useTranslate();
    return (
        <ListGuesser {...props} title="Discount codes list" sort={{field: 'code', order: 'ASC'}}
                     bulkActionButtons={false}>
            <TextField source={"code"} label="resources.discount_codes.fields.code"/>
            <FunctionField
                label="resources.discount_codes.fields.type"
                render={record => translate(getDiscountType(record.type))}
            />
            <TextField source={"value"} label="resources.discount_codes.fields.value"/>
            <DateField source={"validFrom"} label="resources.discount_codes.fields.validFrom"/>
            <DateField source={"validTo"} label="resources.discount_codes.fields.validTo"/>
            <BooleanField source={"active"} label="resources.discount_codes.fields.active"/>
        </ListGuesser>
    );
};

export default DiscountCodesList;
