import React from "react";
import {
    Edit,
    FormTab,
    TabbedForm,
    TextInput,
    BooleanInput,
    NumberInput,
    ReferenceInput,
    SelectInput,
    ImageField,
    DateTimeInput,
    required,
    maxLength,
    minLength,
    AutocompleteArrayInput,
    ReferenceArrayInput,
    SelectArrayInput,
    SimpleFormIterator,
    ArrayInput,
    AutocompleteInput,
    useTranslate,
    FileInput,
    FileField,
    RadioButtonGroupInput,
    NullableBooleanInput
} from 'react-admin';
import RichTextInput from "ra-input-rich-text";
import {Grid, FormControlLabel, Radio, RadioGroup} from '@material-ui/core';
import config from "../../config/config.json";

const validateRequired = [required()];
const validateConfigLength = [maxLength(4)];

export const PackageMainTab = ({packageType, setPackageType, ...rest}) => {
    const translate = useTranslate();
    return (
        <FormTab label="resources.affiliates.tab.package.general" {...rest}>
            <Grid container spacing={1} style={{width: "100%"}}>
                <Grid item xs={12}>
                    <ReferenceInput label="resources.affiliates.fields.package.partner"
                                    source="partner"
                                    reference="affiliate_partners"
                                    validate={validateRequired}
                                    fullWidth
                                    perPage={0}
                                    filter={{pagination: false}}
                    >
                        <SelectInput optionText={(choice) => {
                            if (typeof choice?.name !== 'undefined') {
                                return `[${choice.originId}] ${choice.name}`;
                            }
                        }}/>
                    </ReferenceInput>
                </Grid>
            </Grid>
            <Grid container spacing={1} style={{width: "100%"}}>
                <Grid item xs={12}>
                    <ReferenceInput label="resources.affiliates.fields.package.subscription" source="subscription"
                                    reference="subscriptions"
                                    validate={validateRequired}
                                    fullWidth
                                    perPage={0}
                                    filter={{pagination: false}}
                    >
                        <SelectInput optionText={(choice) => {
                            if (typeof choice?.name !== 'undefined') {
                                return `[${choice.originId}] ${choice.name}`;
                            }
                        }}/>
                    </ReferenceInput>
                </Grid>
            </Grid>
            <Grid container spacing={1} style={{width: "100%"}}>
                <Grid item xs={12}>
                    <TextInput label="resources.affiliates.fields.package.description" source="description" fullWidth
                               validate={validateRequired}/>
                </Grid>
            </Grid>
            <Grid container spacing={1} style={{width: "100%"}}>
                <Grid item md={2} sm={12}>
                    <BooleanInput label="resources.affiliates.fields.package.active" source={"active"}/>
                </Grid>
                <Grid item md={10} sm={12}>
                    <RadioButtonGroupInput disabled={(setPackageType === undefined)}
                                           label={translate("resources.affiliates.fields.package.type")}
                                           source={"type"}
                                           choices={[
                                               {
                                                   id: 'internal',
                                                   name: translate("resources.affiliates.fields.package.type.internal")
                                               },
                                               {
                                                   id: 'external',
                                                   name: translate("resources.affiliates.fields.package.type.external")
                                               },
                                           ]}
                                           onChange={(e) => setPackageType(e)}
                                           defaultValue={packageType}
                    />
                </Grid>
            </Grid>
        </FormTab>
    );
};

export const PackageInternalDataTab = ({...rest}) => {
    return (
        <FormTab label="resources.affiliates.tab.package.internal" {...rest}>
            <Grid container spacing={1} style={{width: "100%"}}>
                <Grid item md={6}>
                    <NumberInput label="resources.affiliates.fields.package.count" source={"count"}
                                 validate={validateRequired} fullWidth/>
                </Grid>
            </Grid>
            <Grid container spacing={1} style={{width: "100%"}}>
                <Grid item md={6}>
                    <DateTimeInput label="resources.affiliates.fields.package.expire_date" validate={validateRequired}
                                   source={"expireDate"}
                    />
                </Grid>
            </Grid>
        </FormTab>
    );
};
export const PackageExternalDataTab = ({...rest}) => {
    return (
        <FormTab label="resources.affiliates.tab.package.external" {...rest}>
            <Grid container spacing={1} style={{width: "100%"}}>
                <Grid item md={12}>
                    <FileInput source="packageFile" label="resources.affiliates.fields.package.file"
                               accept=".csv, .txt, text/plain" multiple={false}>
                        <FileField source="packageFileName" title="title"/>
                    </FileInput>
                </Grid>
            </Grid>
            <Grid container spacing={1} style={{width: "100%"}}>
                <Grid item md={12}>
                    <BooleanInput label="resources.affiliates.fields.package.file_headers" source="headers"/>
                </Grid>
            </Grid>
            <Grid container spacing={1} style={{width: "100%"}}>
                <Grid item md={12}>
                    <ArrayInput source={"configuration"} label={"resources.affiliates.fields.package.configuration"} validate={validateConfigLength}>
                        <SimpleFormIterator>
                            <TextInput label={"resources.affiliates.fields.package.add_configuration"} inputProps={{maxLength: 4, style: {textTransform: 'uppercase'}}} fullWidth/>
                        </SimpleFormIterator>
                    </ArrayInput>
                </Grid>
            </Grid>
            <Grid container spacing={1} style={{width: "100%"}}>
                <Grid item md={12}>
                    <DateTimeInput label="resources.affiliates.fields.package.expire_date" validate={validateRequired}
                                   source={"expireDate"}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={1} style={{width: "100%"}}>
                <Grid item xs={12}>
                    <ReferenceInput
                        source={"mail"}
                        reference={"affiliate_partner_mails"}
                        validate={validateRequired}
                        label="resources.affiliates.fields.package.mail"
                        filter={{pagination: false}}
                        perPage={0}
                        sort={{field: 'name', order: 'ASC'}}
                        fullWidth
                        allowEmpty={false}
                    >
                        <SelectInput optionText={(choice) => {
                            if (typeof choice?.name !== 'undefined') {
                                return `[${choice.originId}] ${choice.name}`;
                            }
                        }}/>
                    </ReferenceInput>
                </Grid>
            </Grid>
        </FormTab>
    );
};