import React from "react";
import {TabbedForm, Create} from 'react-admin';
import config from "../../config/config.json";
import {
    PartnerDataTab,
} from "./PartnersForms";


const PartnersCreate = props => (
    <Create {...props} redirect="list">
        <TabbedForm variant={config.form.variant} margin={config.form.margin}>
            <PartnerDataTab />
        </TabbedForm>
    </Create>
);

export default PartnersCreate;