import React from "react";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    img: {
        margin: '0.5rem',
        maxHeight: '10rem',
        maxWidth: '10rem'
    },
});

const ImageUrlField = ({ record, source, labelFrom }) => {
    const classes = useStyles();
    if (!record || !record[source]) {
        return null;
    }

    return <img alt={record[labelFrom]} src={`${record[source]}`} className={classes.img}/>;
};

export default ImageUrlField;