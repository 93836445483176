import TestimonialsIcon from '@material-ui/icons/FormatQuote';
import TestimonialsEdit from "./TestimonialsEdit";
import TestimonialsList from "./TestimonialsList";
import TestimonialsCreate from "./TestimonialsCreate";
import TestimonialsShow from "./TestimonialsShow";

export default {
    edit: TestimonialsEdit,
    create: TestimonialsCreate,
    list: TestimonialsList,
    show: TestimonialsShow,
    icon: TestimonialsIcon,
    options: {
        label: "Testimonials"
    }
};