import React from "react";
import {TabbedForm, Create} from 'react-admin';
import config from "../../config/config.json";
import {
    BannersGeneralFormTab,
} from "./BannersForms";

const BannersCreate = props => (
    <Create {...props}>
        <TabbedForm variant={config.form.variant} margin={config.form.margin}>
            <BannersGeneralFormTab />
        </TabbedForm>
    </Create>
);

export default BannersCreate;