import React from "react";
import {Create} from 'react-admin';
import {SimpleAuthsForm} from "./AuthsForms";

const AuthsCreate = props => (
    <Create {...props}>
        <SimpleAuthsForm />
    </Create>
);

export default AuthsCreate;