import React from "react";
import {
    FormTab,
    TextInput,
    BooleanInput,
    ImageInput,
    ImageField,
    AutocompleteArrayInput,
    ReferenceArrayInput,
    required,
    ArrayInput,
    SimpleFormIterator,
    maxLength
} from 'react-admin';
import RichTextInput from "ra-input-rich-text";

const validateName = [required()];
const validateSurname = [required()];
const validateShortDescription = [maxLength(100)];
const validateMetaTileLength = [maxLength(70)];
const validateMetaDescription = [maxLength(160)];

export const ExpertsSEOFormTab = ({...rest}) =>
    <FormTab label="SEO" {...rest}>
        <TextInput source={"metaTitle"}  fullWidth validate={validateMetaTileLength} />
        <TextInput source={"metaDescription"}  fullWidth validate={validateMetaDescription} />
        <ArrayInput source={"keywords"}>
            <SimpleFormIterator>
                <TextInput label={"resources.experts.fields.keyword"} fullWidth />
            </SimpleFormIterator>
        </ArrayInput>
    </FormTab>


export const ExpertsGeneralFormTab = ({...rest}) =>
    <FormTab label="Content" {...rest}>
        <TextInput source={"name"} fullWidth validate={validateName}/>
        <TextInput source={"surname"} fullWidth validate={validateSurname}/>
        <TextInput source={"academicDegree"} fullWidth helperText="resources.experts.validators.academicDegreeMaxLength"/>
        <RichTextInput source={"shortDescription"} fullWidth validate={validateShortDescription}/>
        <RichTextInput source={"description"} fullWidth/>
        <BooleanInput source={"active"} fullWidth/>
        <BooleanInput source={"promoted"} fullWidth helperText="Expert's distinction among others on main page"/>
    </FormTab>

export const ExpertsTrainingsFormTab = ({...rest}) => (
    <FormTab label="Trainings" path="trainings" {...rest}>
        <ReferenceArrayInput
            label="Trainings"
            reference="trainings"
            source="trainings"
            fullWidth
            filter={{ pagination: false }}
            format={v => {
                if (!v) {
                    return null;
                }
                for (let i = 0; i < v.length; i++) {
                    if (typeof v[i] === 'object') {
                        v[i] = v[i]['@id'];
                    }
                }
                return v;
            }}
        >
            <AutocompleteArrayInput />
        </ReferenceArrayInput>
    </FormTab>
);

export const ExpertsMediaFormTab = ({children, ...rest}) => (
    <FormTab label="Media" {...rest}>
        <ImageInput
            fullWidth
            source="imageFile"
            label="resources.experts.fields.image"
            accept=".jpg,.jpeg,.png,.webp,.gif"
            placeholder={<p>Dodaj zdjęcie</p>}
            multiple={false}
        >
            <ImageField source="imageFile" title="title" />
        </ImageInput>
        {children}
    </FormTab>
);