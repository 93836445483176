import CategoriesIcon from '@material-ui/icons/AccountTree';
import CategoriesList from './CategoriesList';
import CategoriesCreate from './CategoriesCreate';
import CategoriesEdit from './CategoriesEdit';
import CategoriesShow from "./CategoriesShow";

export default {
    edit: CategoriesEdit,
    create: CategoriesCreate,
    list: CategoriesList,
    show: CategoriesShow,
    icon: CategoriesIcon,
    options: {
        label: "Categories"
    }
};