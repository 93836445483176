import React from 'react';
import ListGuesser from "@api-platform/admin/lib/ListGuesser";
import { TextField, SelectField, BooleanField, DateField } from 'react-admin';

const SubscriptionsList = props => {
    return (
        <ListGuesser {...props} title="Subscriptions list" sort={{ field: 'name', order: 'ASC' }}>
            <TextField source={"brand.name"} label={"resources.subscriptions.fields.brand"}/>
            <TextField source={"name"}/>
            <TextField source={"marketingCodeInvoice"}/>
            <TextField source={"marketingCodePayu"}/>
            <TextField source={"accessLevel"}/>
            <SelectField source="subType" choices={[
                { id: 'month', name: 'resources.subscriptions.fields.type.month' },
                { id: 'quarter', name: 'resources.subscriptions.fields.type.quarter' },
                { id: 'semiAnnual', name: 'resources.subscriptions.fields.type.semiAnnual' },
                { id: 'annual', name: 'resources.subscriptions.fields.type.annual' },
                { id: 'free', name: 'resources.subscriptions.fields.type.free' },
                { id: 'test', name: 'resources.subscriptions.fields.type.test' }
            ]} />
            <BooleanField source={"active"}/>
            <DateField source={"createdAt"} showTime/>
            <DateField source={"updatedAt"} showTime/>
        </ListGuesser>
    );
};

export default SubscriptionsList;