import React from 'react';
import ListGuesser from "@api-platform/admin/lib/ListGuesser";
import {
    ArrayField, SingleFieldList, ChipField, DateField, BooleanField, TextField
} from 'react-admin';

const CategoriesList = props => (
    <ListGuesser {...props} title="Category list" sort={{ field: 'createdAt', order: 'DESC' }}>
        <TextField source={"brand.name"} label={"resources.categories.fields.brand"}  emptyText="-"/>
        <TextField source={"name"}/>
        <BooleanField source={"active"}/>
        <ArrayField source={"children"} fieldKey="@id">
            <SingleFieldList linkType="show">
                <ChipField source="name"/>
            </SingleFieldList>
        </ArrayField>
        <DateField source={"createdAt"} showTime/>
        <DateField source={"updatedAt"} showTime/>
    </ListGuesser>
);

export default CategoriesList;