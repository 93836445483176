import React , { useEffect, useState }from "react";
import {TabbedForm, Create} from 'react-admin';
import config from "../../config/config.json";
import {
    PackageMainTab,
    PackageInternalDataTab,
    PackageExternalDataTab
} from "./PackageForms";

const PackagesCreate = props => {
    const [packageType, setPackageType] = useState(props.type === undefined? 'internal':props.type);

    return (
        <Create {...props}>
            <TabbedForm variant={config.form.variant} margin={config.form.margin}>
                <PackageMainTab setPackageType={setPackageType} packageType={packageType}/>
                {packageType === 'internal' && <PackageInternalDataTab/>}
                {packageType === 'external' && <PackageExternalDataTab />}
            </TabbedForm>
        </Create>
    )
};


export default PackagesCreate;