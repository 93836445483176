import React, {useState, cloneElement} from 'react';
import {
    List, Datagrid, TextField, SelectField, DateField, ExportButton,  useListContext, TopToolbar, sanitizeListRestProps
} from 'react-admin';
import AsideOrders from './AsideOrders'

import {

} from 'react-admin';


const ListActions = (props) => {
    const {
        className,
        exporter,
        filters,
        maxResults,
        ...rest
    } = props;
    const {
        currentSort,
        resource,
        displayedFilters,
        filterValues,
        showFilter,
        total,
    } = useListContext();
    return (
        <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
            {filters && cloneElement(filters, {
                resource,
                showFilter,
                displayedFilters,
                filterValues,
                context: 'button',
            })}
            <ExportButton
                disabled={total === 0}
                resource={resource}
                sort={currentSort}
                filterValues={filterValues}
                maxResults={maxResults}
            />
        </TopToolbar>
    );
};

const OrdersList = (props) => {
    const [aside, setAside] = useState(null);

    const showAsideOrder = (id) => {
        setAside(<AsideOrders id={id} />);
    }

    return (
        <List
            id={props.id}
            resource={props.resource}
            basePath={props.basePath}
            hasCreate={props.hasCreate}
            hasEdit={props.hasEdit}
            hasList={props.hasList}
            hasShow={props.hasShow}
            aside={aside}
            sort={{ field: 'updatedAt', order: 'DESC' }}
            actions={<ListActions />}
        >
            <Datagrid rowClick={showAsideOrder}>
                <TextField source={"originId"}/>
                <TextField source={"paymentType"}/>
                <TextField source={"orderId"}/>
                <TextField source={"paymentId"}/>
                <SelectField source="status" choices={[
                    { id: 'PROGRESS', name: 'resources.orders.fields.status.progress' },
                    { id: 'PENDING', name: 'resources.orders.fields.status.pending' },
                    { id: 'COMPLETE', name: 'resources.orders.fields.status.complete' },
                    { id: 'CANCEL', name: 'resources.orders.fields.status.cancel' },
                    { id: 'FAILURE', name: 'resources.orders.fields.status.failure' },
                    { id: 'ABANDON', name: 'resources.orders.fields.status.abandon' }
                ]} />
                <DateField source={"createdAt"} showTime/>
                <DateField source={"updatedAt"} showTime/>
                {/*<ShowButton />*/}
            </Datagrid>
        </List>
    );
}

export default OrdersList;