const JWTManager = () => {
    const getToken = () => {
        return sessionStorage.getItem("token");
    };

    const setToken = (token) => {
        sessionStorage.setItem("token", token);
        return true;
    };

    const ereaseToken = () => {
        sessionStorage.removeItem("token");
        return true;
    };

    return {
        ereaseToken,
        setToken,
        getToken,
    }
}

export default JWTManager();