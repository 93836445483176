import React from "react";
import {TabbedForm, Create} from 'react-admin';
import {TestimonialsGeneralFormTab} from "./TestimonialsForms";
import config from "../../config/config.json";

const TestimonialsCreate = (props) => {
    return (
        <Create {...props} title="Add Testimonial">
            <TabbedForm variant={config.form.variant} margin={config.form.margin}>
                <TestimonialsGeneralFormTab />
            </TabbedForm>
        </Create>
    );
};

export default TestimonialsCreate;