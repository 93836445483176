import AdsIcon from '@material-ui/icons/AttachMoney';
import AdsList from './AdsList';
import AdsCreate from './AdsCreate';
import AdsEdit from './AdsEdit';
import AdsShow from './AdsShow';

export default {
    create: AdsCreate,
    list: AdsList,
    icon: AdsIcon,
    edit: AdsEdit,
    show: AdsShow,
    options: {
        dividerUp: false,
        label: "Ads"
    }
};