import React from 'react';
import {
    Show, TabbedShowLayout, Tab, TextField, BooleanField, RichTextField,
    NumberField, SelectField, DateField, Button
} from 'react-admin';

const OrdersShow = props => (
    <Show {...props} actions={<Button label="Jakaś customowa akcja na zamówieniu, np. ponowne przetworzenie"/>}>
        <TabbedShowLayout>
            <Tab label="General">
                <TextField source={"name"} addLabel={true} />
                <RichTextField source="description" addLabel={true}/>
                <BooleanField source={"active"} addLabel={true} />
                <DateField source={"createdAt"} showTime addLabel={true}/>
                <DateField source={"updatedAt"} showTime addLabel={true}/>
            </Tab>
            <Tab label="resources.subscriptions.configuration">
                <NumberField source="accessLevel" addLabel={true}/>
                <SelectField source="subType" choices={[
                    { id: 'month', name: 'resources.subscriptions.fields.type.month' },
                    { id: 'quarter', name: 'resources.subscriptions.fields.type.quarter' },
                    { id: 'semiAnnual', name: 'resources.subscriptions.fields.type.semiAnnual' },
                    { id: 'annual', name: 'resources.subscriptions.fields.type.annual' }
                ]} addLabel={true} />
                <NumberField source={"priceNet"} addLabel={true} />
                <NumberField source={"priceGross"} addLabel={true} />
                <NumberField source={"taxValue"} addLabel={true} />
            </Tab>
        </TabbedShowLayout>
    </Show>
);

export default OrdersShow;