import React from "react";
import {Edit} from 'react-admin';
import {SimpleAuthsForm} from "./AuthsForms";

const AuthsEdit = props => (
    <Edit {...props}>
        <SimpleAuthsForm />
    </Edit>
);

export default AuthsEdit;