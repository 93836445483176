import * as React from "react";
import { Layout } from 'react-admin';
import DefaultAppBar from './appBars/DefaultAppBar';
import Menu from "./menu/DefaultMenu";
import {mainTheme} from "./theme/themes";

const CustomLayout = (props) => <Layout
        {...props}
        appBar={DefaultAppBar}
        menu={Menu}
        theme={ mainTheme }
    />;

export default CustomLayout;