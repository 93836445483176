import React from "react";
import { FilterList, FilterListItem } from 'react-admin';
import CheckIcon from '@material-ui/icons/Check';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import {
    endOfYesterday,
    startOfWeek,
    startOfMonth
} from 'date-fns';

export const IsActiveFilter = () => (
    <FilterList
        label="Is active"
        icon={<CheckIcon />}
    >
        <FilterListItem
            label="Yes"
            value={{ active: true }}
        />
        <FilterListItem
            label="No"
            value={{ active: false }}
        />
    </FilterList>
)

export const IsArchiveFilter = () => (
    <FilterList
        label="Is archive"
        icon={<CheckIcon />}
    >
        <FilterListItem
            label="Yes"
            value={{ archive: true }}
        />
        <FilterListItem
            label="No"
            value={{ archive: false }}
        />
    </FilterList>
)

export const DateCreatedGreaterThanFilter = () => (
    <FilterList icon={<AccessTimeIcon />} label={"Created at"}>
        <FilterListItem
            label={"Today"}
            value={{
                'createdAt[after]': endOfYesterday().toISOString(),
                'createdAt[before]': undefined
            }}
        />
        <FilterListItem
            label={"This week"}
            value={{
                'createdAt[after]': startOfWeek(new Date()).toISOString(),
                'createdAt[before]': undefined
            }}
        />
        <FilterListItem
            label={"This month"}
            value={{
                'createdAt[after]': startOfMonth(new Date()).toISOString(),
                'createdAt[before]': undefined
            }}
        />
        <FilterListItem
            label={"Older than last month"}
            value={{
                'createdAt[after]': undefined,
                'createdAt[before]': startOfMonth(new Date()).toISOString()
            }}
        />
    </FilterList>
)