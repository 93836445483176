import React from "react";
import { Show,
    TextField,
    RichTextField,
    ImageField} from 'react-admin';

const BannersShow = props => (
    <Show {...props}>
        <TextField label="resources.banners.fields.title" source="title" fullWidth />
        <RichTextField label="resources.banners.fields.content" source="content" fullWidth />
        <TextField label="resources.banners.fields.buttonName" source="buttonName" fullWidth/>
        <TextField label="resources.banners.fields.uri"  source="uri" fullWidth />
        <TextField label="resources.banners.fields.type" source="type" fullWidth />
        <ImageField source="imageUrl" title="title" fullWidth/>
    </Show>
);

export default BannersShow;