import React from "react";
import {
    Edit,
    FormTab,
    TabbedForm,
    TextInput,
    BooleanInput,
    ImageField,
    required,
    SelectInput,
    DateTimeInput,
    Validate
} from 'react-admin';
import RichTextInput from "ra-input-rich-text";
import { Grid } from '@material-ui/core';
import discountTypes from "./types/discountTypes"

const validateRequired = [required()];


const validateIntegerInput = (value, input) => {
    if (input.type === 'cash') {
        if (value < 1) {
            return "ra.validation.discount.cash.value";
        }
    } else if (input.type === 'percent') {
        if (value < 1 || value > 99) {
            return "ra.validation.discount.percent.value";
        }
    }
    return undefined;
};

const IntegerInput = ({ validator, record, ...rest }) => {

    return (
        <TextInput
            {...rest}
            type="number"
            validate={[required(), validateIntegerInput]}
            parse={value => parseInt(value)}
        />
    );
};
const TypeSelect = ({ ...rest }) => (
    <SelectInput
        {...rest}
        choices={discountTypes}
        defaultValue='percent'
    />
);
export const DiscountCodesDataTab = ({...rest}) => {
    return (
        <FormTab label="resources.discount_codes.form" {...rest}>
            <Grid container spacing={1} style={{ width: "100%"}}>
                <Grid item xs={4}>
                    <TextInput label="resources.discount_codes.fields.code" source="code" fullWidth validate={validateRequired} />
                </Grid>
                <Grid item xs={4}>
                    <IntegerInput source="value" label="resources.discount_codes.fields.value" fullWidth/>
                </Grid>
                <Grid item xs={4}>
                    <TypeSelect  source="type" label="resources.discount_codes.fields.type" fullWidth />
                </Grid>
            </Grid>
            <Grid container spacing={1} style={{ width: "100%"}}>
                <Grid item xs={4} md={4}>
                    <Grid container spacing={1} style={{ width: "100%"}}>
                        <Grid item xs={6} md={6}>
                            <DateTimeInput label="resources.discount_codes.fields.validFrom" source={"validFrom"} fullWidth />
                        </Grid>
                        <Grid item xs={6} md={6}>
                            <DateTimeInput label="resources.discount_codes.fields.validTo" source={"validTo"} fullWidth />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={8} md={8}>
                    <BooleanInput label="resources.discount_codes.fields.active" source={"active"} />
                </Grid>
            </Grid>
            </FormTab>
    );
};
