import ExpertsIcon from '@material-ui/icons/People';
import ExpertsEdit from "./ExpertsEdit";
import ExpertsList from "./ExpertsList";
import ExpertsCreate from "./ExpertsCreate";
import ExpertsShow from "./ExpertsShow";

export default {
    edit: ExpertsEdit,
    create: ExpertsCreate,
    list: ExpertsList,
    show: ExpertsShow,
    icon: ExpertsIcon,
    options: {
        label: "Experts"
    }
};