import React from 'react';
import ShowGuesser from "@api-platform/admin/lib/ShowGuesser";
import FieldGuesser from "@api-platform/admin/lib/FieldGuesser";
import { ArrayField, SingleFieldList, BooleanField, FunctionField } from 'react-admin';
import gravatar from 'gravatar';
import SimpleChipField from "../../components/fields/SimpleChipField";

const AuthsShow = props => (
    <ShowGuesser {...props}>
        <FunctionField label="Avatar" render={record => {
            return <img src={gravatar.url(record.email, {d: 'robohash'})} alt={record.username}/>;
        }} />
        <FieldGuesser source={"username"} />
        <FieldGuesser source={"email"} addLabel={true} />
        <ArrayField source="roles">
            <SingleFieldList>
                <SimpleChipField variant="outlined"/>
            </SingleFieldList>
        </ArrayField>
        <BooleanField source={"active"} addLabel={true} />
        <FieldGuesser source={"createdAt"} addLabel={true} />
        <FieldGuesser source={"updatedAt"} addLabel={true} />
    </ShowGuesser>
);

export default AuthsShow;