import React from "react";
import {
    Edit,
    TabbedForm,
    required,
    FormTab
} from 'react-admin';
import config from "../../config/config.json";
import {
    DiscountCodesDataTab
} from "./CodeForms";

const validateName = [required()];


const DiscountCodesEdit = props => {
    return (
        <Edit undoable={false} {...props}>
            <TabbedForm variant={config.form.variant} margin={config.form.margin}>
                <DiscountCodesDataTab />
            </TabbedForm>
        </Edit>
    )
}
export default DiscountCodesEdit