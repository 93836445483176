import OrdersIcon from '@material-ui/icons/ShoppingCart';
import OrdersEdit from "./OrdersEdit";
import OrdersList from "./OrdersList";
import OrdersShow from "./OrdersShow";

export default {
    edit: OrdersEdit,
    list: OrdersList,
    show: OrdersShow,
    icon: OrdersIcon,
    options: {
        label: "Orders"
    }
};