import React from "react";
import {TabbedForm, Create} from 'react-admin';
import config from "../../config/config.json";
import {
    DiscountCodesDataTab,
} from "./CodeForms";

const DiscountCodesCreate = props => (
    <Create {...props} redirect="list">
        <TabbedForm variant={config.form.variant} margin={config.form.margin}>
            <DiscountCodesDataTab />
        </TabbedForm>
    </Create>
);

export default DiscountCodesCreate;