import React from "react";
import {ShowGuesser} from "@api-platform/admin";
import { Datagrid, ArrayField, NumberField, TextField, DateField, BooleanField, RichTextField } from 'react-admin';

const CategoriesShow = props => (
    <ShowGuesser {...props}>
        <TextField source={"name"} addLabel={true} />
        <RichTextField source={"description"} addLabel={true} />
        <BooleanField source={"active"} addLabel={true} />
        <NumberField source={"lvl"} addLabel={true} />
        <TextField source="parent.name"/>
        <ArrayField source="children">
            <Datagrid>
                <TextField source={"name"} />
                <DateField source={"createdAt"} showTime/>
                <DateField source={"updatedAt"} showTime/>
            </Datagrid>
        </ArrayField>
        <ArrayField source="trainings" fieldKey="@id" basePath="/trainings">
            <Datagrid>
                <TextField source={"name"} />
                <DateField source={"createdAt"} showTime/>
                <DateField source={"updatedAt"} showTime/>
            </Datagrid>
        </ArrayField>
        <TextField source="promotedTraining.name" />
        <DateField source={"createdAt"} showTime/>
        <DateField source={"updatedAt"} showTime/>
    </ShowGuesser>
);

export default CategoriesShow;