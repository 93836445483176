import * as React from "react";
import {
    TextField, List as AdminList, Datagrid, ShowButton,
    EditButton, DateField, ImageField, FunctionField
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import SimpleChipField from "../../components/fields/SimpleChipField";
import DeleteButtonWithConfirm from "../../components/buttons/DeleteButtonWithConfirm";

const useStyles = makeStyles((theme) => ({
    quizImage: {
        '& > img': {
            maxWidth: '150px'
        }
    }
}));

const QuizzesList = props => {
    let classes = useStyles();
    return (
        <AdminList
            {...props}
            sort={{field: 'createdAt', order: 'DESC'}}
        >
            <Datagrid>
                <ImageField source="imageUrl" className={classes.quizImage}/>
                <TextField source={"name"}/>
                <FunctionField
                    textAlign="center"
                    label="resources.quizzes.fields.percentPointsToUnlockCertificate"
                    render={record => `${record.percentPointsToUnlockCertificate}%`}
                />
                <FunctionField
                    label="resources.quizzes.fields.trainings"
                    render={record => {
                        return record.training !== null ?
                            <SimpleChipField record={record.training.name}/> :
                            ' - ';
                    }}
                />
                <DateField source={"createdAt"} showTime/>
                <DateField source={"updatedAt"} showTime/>
                <ShowButton />
                <EditButton />
                <DeleteButtonWithConfirm />
            </Datagrid>
        </AdminList>
    )
};

export default QuizzesList;