import React, {useState, useEffect} from 'react';
import {useNotify, LinearProgress, SelectArrayInput, Filter} from 'react-admin';
import {makeStyles} from '@material-ui/core';
import getTrainingsTypes from "../../api/getTrainingsTypes";
import config from "../../config/config.json";

const useStyles = makeStyles({
    filterStyle: {
        '& > div': {
            width: '100%',
            margin: '20px 0'
        }
    }
});

function TrainingsTypesFilter(props) {
    const classes = useStyles();
    const notify = useNotify();
    const [trainingsTypes, setTrainingsTypes] = useState(null);

    useEffect(() => {
        getTrainingsTypes()
            .then(response => {
                if (!response.ok) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then(data =>
                setTrainingsTypes(data.map(item => {
                    return {
                        id: item.type,
                        name: item.name
                    }
                }))
            )
            .catch(e => notify('Error: problem with fetch trainings types', 'warning'));
    }, [notify, setTrainingsTypes]);

    if (trainingsTypes === null) {
        return <LinearProgress/>;
    }

    return (
        <Filter className={classes.filterStyle} variant={config.filter.variant}>
            <SelectArrayInput
                {...props}
                allowEmpty={true}
                fullWidth
                source="subType"
                choices={trainingsTypes}
                alwaysOn
            />
        </Filter>
    );
}

export default TrainingsTypesFilter;