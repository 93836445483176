import React from "react";
import {TabbedForm, Edit, ImageField} from 'react-admin';
import ExpertAvatar from "../../components/addons/ExpertAvatar";
import {ExpertsGeneralFormTab, ExpertsMediaFormTab, ExpertsTrainingsFormTab, ExpertsSEOFormTab} from "./ExpertsForms";
import config from "../../config/config.json";

const Title = ({ record }) => {
    return (
        <span><ExpertAvatar record={record}/></span>
    )
};

const ExpertsEdit = (props) => {
    return (
        <Edit {...props} title={<Title />}>
            <TabbedForm variant={config.form.variant} margin={config.form.margin}>
                <ExpertsGeneralFormTab />
                <ExpertsTrainingsFormTab />
                <ExpertsMediaFormTab>
                    <ImageField source="imageUrl" title="title" fullWidth />
                </ExpertsMediaFormTab>
                <ExpertsSEOFormTab />
            </TabbedForm>
        </Edit>
    );
};

export default ExpertsEdit;