import React, {useState} from "react";
import OutlinedInput from '@material-ui/core/OutlinedInput';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import config from "../../config/config.json";
import {InputGuesser} from "@api-platform/admin";
import {
    SimpleForm,
    TextInput,
    SelectArrayInput,
    PasswordInput,
    FormDataConsumer,
    required,
    email,
    minLength
} from 'react-admin';

const RepeatPasswordInput = (props) => {
    const [visibility, setVisibility] = useState(false);
    const handleChange = (event) => {
        props.setRepeatPassword(event.target.value);
    }
    const handleVisibility = () => {
        setVisibility(!visibility);
    }

    return (
        <FormControl className={props.className || null} margin={props.margin || 'normal'} variant={props.variant || 'default'}>
            <InputLabel htmlFor="repeat-password">Password</InputLabel>
            <OutlinedInput
                id="repeat-password"
                type={visibility?'text':'password'}
                name='repeatPassword'
                value={props.repeatPassword}
                onChange={handleChange}
                label="Password"
                placeholder="Password"
                endAdornment={
                    <InputAdornment position="start">
                        <IconButton
                            aria-label="toggle password visibility"
                            edge="end"
                            onClick={handleVisibility}
                        >
                            {visibility ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                    </InputAdornment>
                }
            />
        </FormControl>
    );
}

export const SimpleAuthsForm = (props) => {
    const [repeatPassword, setRepeatPassword] = useState('');
    const passwordRepeatValidation = (value) => {
        if (value !== repeatPassword) {
            return 'Repeated password not match with password';
        }
        return null;
    }
    const validateAge = [minLength(5), passwordRepeatValidation];
    const validateUsername = [required()];
    const validateEmail = [email()];

    return (
        <SimpleForm {...props} variant={config.form.variant} margin={config.form.margin}>
            <TextInput source="username" validate={validateUsername}/>
            <TextInput source="email" validate={validateEmail}/>
            <RepeatPasswordInput
                repeatPassword={repeatPassword}
                setRepeatPassword={setRepeatPassword}
            />
            <FormDataConsumer>
                {({formData, ...rest}) => repeatPassword &&
                    <PasswordInput source="plainPassword" validate={validateAge} {...rest} label="Confirm password"/>
                }
            </FormDataConsumer>
            <SelectArrayInput source="roles" choices={[
                {id: 'ROLE_API', name: 'ROLE_API'},
                {id: 'ROLE_ADMIN', name: 'ROLE_ADMIN'},
                {id: 'ROLE_SUPER_ADMIN', name: 'ROLE_SUPER_ADMIN'}
            ]}/>
            <InputGuesser source="active"/>
        </SimpleForm>
    );
}